import React from 'react'
import { ConnectedRouter } from 'react-router-redux'
import { Route, Redirect, Switch, BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import ResetPassword from './containers/Pages/Public/ResetPassword'
import ForgotPassword from './containers/Pages/Public/ForgotPassword'
import Signin from './containers/Pages/Public/Signin'
import App from './containers/App/App'
import UserAuth from './containers/Pages/Public/UserAuth'

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        isLoggedIn ?
          (
            <Component {...props} />
          )
          :
          (
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: props.location },
                search: ''
              }}
            />
          )
      )}
    />
  )
}

const PublicRoutes = ({ isLoggedIn, history }) => {
  return (
    <div>
      <ConnectedRouter history={history}>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path='/signin'
              component={Signin}
            />
            <Route
              exact
              path='/auth'
              component={UserAuth}
            />
            <Route
              exact
              path={'/forgot-password'}
              component={ForgotPassword}
            />
            <Route
              exact
              path={'/reset-password/:uuid'}
              component={ResetPassword}
            />
            <RestrictedRoute
              path='/'
              component={App}
              isLoggedIn={isLoggedIn}
            />
          </Switch>
        </BrowserRouter>
      </ConnectedRouter>
    </div>
  )
}


export default connect(state => ({
  isLoggedIn: !!state.Auth.idToken
}))(PublicRoutes)
