import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Radio, Row, Col, Button, Modal, notification, Switch } from 'antd'
import { get } from 'lodash'

import { SecurityStyle } from '../topbar.style'
import AddPhoneNumber from './SecurityComponents/addPhoneNumber'
import AddTOTPDevice from './SecurityComponents/addTOTPDevice'
import VerifyOTP from './SecurityComponents/verifyOTP'
import loadingActions from '../../../redux/loading/actions'
import errorActions from '../../../redux/error/actions'
import UserActions from '../../../containers/redux/user/action'
import ConfigChecker from '../../ConfigChecker'

const { update2FAMethod, sendOTPToUser, disable2FA } = UserActions
const { resetLoadingRedux } = loadingActions
const { resetErrorRedux } = errorActions

const RadioGroup = Radio.Group

const twoFAOptions = {
  0: 'EMAIL_OTP',
  1: 'SMS_OTP',
  2: 'TOTP'
}

class Security extends Component {
  state = {
    selected2FAmethod: 'EMAIL_OTP',
    showAddPhoneNumberModal: false,
    showVerifyOTPModal: false,
    showAddDeviceModal: false,
    update2FAMethodInProcess: false,
    enable2FAValue: false,
    providerConfigEnabled: false
  }

  componentDidMount() {
    const { userProfile = {}, providerDetails = {} } = this.props
    const providerConfigs = get(providerDetails, 'config', [])

    this.setState({ selected2FAmethod: twoFAOptions[get(userProfile, 'default_2fa_option', 0)], enable2FAValue: userProfile.enable_2fa_auth })

    if (!!providerConfigs.find(({
      config_type = {},
      value
    }) => value === "1" && config_type.name === "ENABLE_2FA_AUTH")) {
      this.setState({ providerConfigEnabled: true })
    }

    this.props.resetLoadingRedux(['SEND_SMS_OTP', 'VERIFY_AND_ADD_PHONE', 'VERIFY_TOTP', 'UPDATE_2FA_METHOD', 'VERIFY_USER_OTP'])
    this.props.resetErrorRedux(['SEND_SMS_OTP', 'VERIFY_AND_ADD_PHONE', 'VERIFY_TOTP', 'UPDATE_2FA_METHOD', 'VERIFY_USER_OTP'])
  }

  componentWillReceiveProps(nextProps) {
    const {
      sendSMSOTPLoading, sendSMSOTPError, sendSMSOTPMessage,
      verifyAndAddNumberLoading, verifyAndAddNumberError, verifyAndAddNumberMessage,
      verifyTOTPLoading, verifyTOTPError, verifyTOTPMessage,
      verifyUserOTPLoading, verifyUserOTPError, verifyUserOTPMessage,
      update2FAMethodLoading, update2FAMethodError, update2FAMethodMessage, topbarActiveModal,
      disable2FALoading, disable2FAError, disable2FAMessage
    } = nextProps
    const { selected2FAmethod } = this.state
    const isModalActive = topbarActiveModal === 'security'

    if (isModalActive && sendSMSOTPLoading === false) {
      notification[sendSMSOTPError ? 'error' : 'success']({ message: sendSMSOTPMessage })
      if (sendSMSOTPError === false) {
        this.setState({ showVerifyOTPModal: true, showAddPhoneNumberModal: false })
      } else {
        this.setState({ showAddPhoneNumberModal: false, update2FAMethodInProcess: false, showVerifyOTPModal: false })
      }
      this.props.resetLoadingRedux(['SEND_SMS_OTP'])
      this.props.resetErrorRedux(['SEND_SMS_OTP'])
    }

    if (isModalActive && verifyAndAddNumberLoading === false) {
      notification[verifyAndAddNumberError ? 'error' : 'success']({ message: verifyAndAddNumberMessage })
      if (verifyAndAddNumberError === false) {
        this.props.update2FAMethod({
          body: {
            auth_type: 'SMS_OTP'
          }
        })
        this.setState({ showVerifyOTPModal: false, selected2FAmethod: 'SMS_OTP' })
      }
      this.props.resetLoadingRedux(['VERIFY_AND_ADD_PHONE'])
      this.props.resetErrorRedux(['VERIFY_AND_ADD_PHONE'])
    }

    if (isModalActive && verifyTOTPLoading === false) {
      notification[verifyTOTPError ? 'error' : 'success']({ message: verifyTOTPMessage })
      if (verifyTOTPError === false) {
        this.props.update2FAMethod({
          body: {
            auth_type: 'TOTP'
          }
        })
        this.setState({ showAddDeviceModal: false, selected2FAmethod: 'TOTP' })
      }
      this.props.resetLoadingRedux(['VERIFY_TOTP'])
      this.props.resetErrorRedux(['VERIFY_TOTP'])
    }

    if (update2FAMethodLoading === false) {
      notification[update2FAMethodError ? 'error' : 'success']({ message: update2FAMethodMessage })
      if (update2FAMethodError === false) {
        this.props.handleClose()
      }
      this.props.resetLoadingRedux(['UPDATE_2FA_METHOD'])
      this.props.resetErrorRedux(['UPDATE_2FA_METHOD'])
    }

    if (verifyUserOTPLoading === false) {
      notification[verifyUserOTPError ? 'error' : 'success']({ message: verifyUserOTPMessage })
      if (verifyUserOTPError === false) {
        this.setState({ update2FAMethodInProcess: false, showVerifyOTPModal: false })
        this.props.update2FAMethod({
          body: {
            auth_type: selected2FAmethod
          }
        })
      }
      this.props.resetLoadingRedux(['VERIFY_USER_OTP'])
      this.props.resetErrorRedux(['VERIFY_USER_OTP'])
    }

    if (disable2FALoading === false) {
      notification[disable2FAError ? 'error' : 'success']({ message: disable2FAMessage })
      if (disable2FAError === false) {
        this.setState({ showVerifyOTPModal: false })
      }
      this.props.resetLoadingRedux(['DISABLE_2FA'])
      this.props.resetErrorRedux(['DISABLE_2FA'])
    }
  }

  handle2FAchange = (type) => {
    if (type === 'SMS_OTP' && this.isSMSOTPDisabled()) return
    if (type === 'TOTP' && this.isTOTPDisabled()) return

    this.setState({ selected2FAmethod: type })
  }

  isSMSOTPDisabled = () => {
    const { userProfile = {} } = this.props
    const { phone } = userProfile

    return !phone
  }

  isTOTPDisabled = () => {
    const { userProfile = {} } = this.props
    const { totp_enabled: totpEnabled } = userProfile

    return !totpEnabled
  }

  handleAddPhoneNumber = () => {
    this.setState({ showAddPhoneNumberModal: true, update2FAMethodInProcess: false })
    this.props.handleClose()
  }

  handleAddDevice = () => {
    this.setState({ showAddDeviceModal: true, update2FAMethodInProcess: false })
    this.props.handleClose()
  }

  handleVerifyOTPAndUpdate2FAMethod = () => {
    const { selected2FAmethod } = this.state

    if (selected2FAmethod !== 'TOTP') {
      this.props.sendOTPToUser({
        body: {
          otp_type: selected2FAmethod === 'SMS_OTP' ? 'sms' : 'email',
          template_type: '2FA'
        }
      })
    }
    this.setState({ showVerifyOTPModal: true, update2FAMethodInProcess: true })
    this.props.handleClose()
  }

  handleVerifyModalCancel = () => {
    this.setState({ showVerifyOTPModal: false, update2FAMethodInProcess: false })
  }

  handleAddPhoneNumberModalCancel = () => {
    this.setState({ showAddPhoneNumberModal: false })
  }

  handleAddTOTPDeviceModalCancel = () => {
    this.setState({ showAddDeviceModal: false })
  }

  handleSwitchChange = value => {
    const { selected2FAmethod } = this.state
    // if (!value) {
    // this.props.disable2FA()
    // }
    this.setState({ enable2FAValue: value })
    if (!value) {
      this.setState({ disable2FAInProcesss: true, showVerifyOTPModal: true })
      this.props.handleClose()
      if (selected2FAmethod !== 'TOTP') {
        this.props.sendOTPToUser({
          body: {
            otp_type: selected2FAmethod === 'SMS_OTP' ? 'sms' : 'email',
            template_type: '2FA-disable'
          }
        })
      }
    }
  }

  render() {
    const { userProfile = {}, update2FAMethodLoading } = this.props
    const { selected2FAmethod, showAddPhoneNumberModal, showVerifyOTPModal, showAddDeviceModal, update2FAMethodInProcess, enable2FAValue, providerConfigEnabled, disable2FAInProcesss } = this.state
    const { default_2fa_option: default2FAOption = 0 } = userProfile
    const isSMSOTPDisabled = this.isSMSOTPDisabled()
    const isTOTPDisabled = this.isTOTPDisabled()

    return (
      <SecurityStyle>
        {
          showAddPhoneNumberModal &&
          <Modal
            visible
            title='Add Phone Number'
            footer={null}
            onCancel={this.handleAddPhoneNumberModalCancel}
          >
            <AddPhoneNumber
              visible={showAddPhoneNumberModal}
            />
          </Modal>
        }
        {
          showVerifyOTPModal &&
          <Modal
            visible
            title='Verify OTP'
            footer={null}
            onCancel={this.handleVerifyModalCancel}
          >
            <VerifyOTP
              visible={showVerifyOTPModal}
              update2FAMethodInProcess={update2FAMethodInProcess}
              disable2FAInProcesss={disable2FAInProcesss}
              selected2FAmethod={selected2FAmethod}
            />
          </Modal>
        }
        {
          showAddDeviceModal &&
          <Modal
            visible
            title='Add TOTP Device'
            footer={null}
            onCancel={this.handleAddTOTPDeviceModalCancel}
          >
            <AddTOTPDevice />
          </Modal>
        }

        <div className='heading'>2-Factor Authentication</div>

        <ConfigChecker requiredConfig="ENABLE_2FA_AUTH" requiredValue="0">
          <div className='switch-containers'>
            <Switch checked={enable2FAValue} onChange={this.handleSwitchChange} /> Enable 2FA
          </div>
        </ConfigChecker>
        {
          (providerConfigEnabled || enable2FAValue) &&
          <div>
            <div className='sub-heading'>Select default 2FA method</div>
            <RadioGroup value={selected2FAmethod} defaultValue={twoFAOptions[default2FAOption]}>
              <Row type='flex' align='middle' className='security-option-container' onClick={() => this.handle2FAchange('EMAIL_OTP')}>
                <Col span={2}><Radio value='EMAIL_OTP' /></Col>
                <Col span={22}>
                  <div className='security-heading'>Email OTP</div>
                  <div className='sub-security-heading'>You will receive OTP through registered email address.</div>
                </Col>
              </Row>
              <Row type='flex' align='middle' className={`security-option-container ${isSMSOTPDisabled && 'disabled'}`} onClick={() => this.handle2FAchange('SMS_OTP')}>
                <Col span={2}><Radio value='SMS_OTP' disabled={isSMSOTPDisabled} /></Col>
                <Col span={22}>
                  <div className='security-heading'>SMS OTP</div>
                  <div className='sub-security-heading'>
                    You will receive OTP through registered mobile number.
                {isSMSOTPDisabled &&
                      <span
                        className='setup-option'
                        onClick={this.handleAddPhoneNumber}
                      >
                        Add phone number
                  </span>}
                  </div>
                </Col>
              </Row>
              <Row type='flex' align='middle' className={`security-option-container ${isTOTPDisabled && 'disabled'}`} onClick={() => this.handle2FAchange('TOTP')}>
                <Col span={2}><Radio value='TOTP' disabled={isTOTPDisabled} /></Col>
                <Col span={22}>
                  <div className='security-heading'>TOTP</div>
                  <div className='sub-security-heading'>Time based OTP. You will need to add authentication code present in authenticator app to login.
                {isTOTPDisabled &&
                      <span
                        className='setup-option'
                        onClick={this.handleAddDevice}
                      >
                        Add Device
                  </span>}
                  </div>
                </Col>
              </Row>
            </RadioGroup>
            <Row type='flex' justify='end'>
              <Button
                type='primary'
                className='update-button'
                onClick={this.handleVerifyOTPAndUpdate2FAMethod}
                loading={!!update2FAMethodLoading}
              >
                Proceed
          </Button>
            </Row>
          </div>
        }
      </SecurityStyle>
    )
  }
}

export default connect(state => ({
  userProfile: state.App.userDetails,

  sendSMSOTPLoading: state.Loading.SEND_SMS_OTP,
  sendSMSOTPError: state.Error.SEND_SMS_OTP,
  sendSMSOTPMessage: state.User.sendSMSOtpMessage,

  verifyAndAddNumberLoading: state.Loading.VERIFY_AND_ADD_PHONE,
  verifyAndAddNumberError: state.Error.VERIFY_AND_ADD_PHONE,
  verifyAndAddNumberMessage: state.User.verifyAndAddNumberMessage,

  verifyTOTPLoading: state.Loading.VERIFY_TOTP,
  verifyTOTPError: state.Error.VERIFY_TOTP,
  verifyTOTPMessage: state.User.verifyTOTPMessage,

  update2FAMethodLoading: state.Loading.UPDATE_2FA_METHOD,
  update2FAMethodError: state.Error.UPDATE_2FA_METHOD,
  update2FAMethodMessage: state.User.update2FAMethodMessage,

  verifyUserOTPLoading: state.Loading.VERIFY_USER_OTP,
  verifyUserOTPError: state.Error.VERIFY_USER_OTP,
  verifyUserOTPMessage: state.User.verifyUserOTPMessage,

  topbarActiveModal: state.User.topbarActiveModal,

  disable2FALoading: state.Loading.DISABLE_2FA,
  disable2FAError: state.Error.DISABLE_2FA,
  disable2FAMessage: state.User.disable2FAMessage,

  providerDetails: state.App.providerDetails
}), {
  update2FAMethod,
  sendOTPToUser,
  disable2FA,
  resetLoadingRedux,
  resetErrorRedux
})(Security)
