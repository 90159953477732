const actions = {

    GET_CLAIM_LIST_REQUEST: 'GET_CLAIM_LIST_REQUEST',
    GET_CLAIM_LIST_SUCCESS: 'GET_CLAIM_LIST_SUCCESS',
    GET_CLAIM_LIST_FAILURE: 'GET_CLAIM_LIST_FAILURE',

    GET_CLAIM_DETAIL_REQUEST: 'GET_CLAIM_DETAIL_REQUEST',
    GET_CLAIM_DETAIL_SUCCESS: 'GET_CLAIM_DETAIL_SUCCESS',
    GET_CLAIM_DETAIL_FAILURE: 'GET_CLAIM_DETAIL_FAILURE',

    CLAIM_EMAIL_LIST_REQUEST: 'CLAIM_EMAIL_LIST_REQUEST',
    CLAIM_EMAIL_LIST_SUCCESS: 'CLAIM_EMAIL_LIST_SUCCESS',
    CLAIM_EMAIL_LIST_FAILURE: 'CLAIM_EMAIL_LIST_FAILURE',

    GET_CLAIM_DASHBOARD_DATA_REQUEST: 'GET_CLAIM_DASHBOARD_DATA_REQUEST',
    GET_CLAIM_DASHBOARD_DATA_SUCCESS: 'GET_CLAIM_DASHBOARD_DATA_SUCCESS',
    GET_CLAIM_DASHBOARD_DATA_FAILURE: 'GET_CLAIM_DASHBOARD_DATA_FAILURE',

    TRIGGER_CLAIM_EVENT_REQUEST: 'TRIGGER_CLAIM_EVENT_REQUEST',
    TRIGGER_CLAIM_EVENT_SUCCESS: 'TRIGGER_CLAIM_EVENT_SUCCESS',
    TRIGGER_CLAIM_EVENT_FAILURE: 'TRIGGER_CLAIM_EVENT_FAILURE',

    TRIGGER_CLAIM_ATTRIBUTE_EVENT_REQUEST: 'TRIGGER_CLAIM_ATTRIBUTE_EVENT_REQUEST',
    TRIGGER_CLAIM_ATTRIBUTE_EVENT_SUCCESS: 'TRIGGER_CLAIM_ATTRIBUTE_EVENT_SUCCESS',
    TRIGGER_CLAIM_ATTRIBUTE_EVENT_FAILURE: 'TRIGGER_CLAIM_ATTRIBUTE_EVENT_FAILURE',

    TRIGGER_CLAIM_SALVAGE_EVENT_REQUEST: 'TRIGGER_CLAIM_SALVAGE_EVENT_REQUEST',
    TRIGGER_CLAIM_SALVAGE_EVENT_SUCCESS: 'TRIGGER_CLAIM_SALVAGE_EVENT_SUCCESS',
    TRIGGER_CLAIM_SALVAGE_EVENT_FAILURE: 'TRIGGER_CLAIM_SALVAGE_EVENT_FAILURE',

    GET_CLAIM_FILTERS_REQUEST: 'GET_CLAIM_FILTERS_REQUEST',
    GET_CLAIM_FILTERS_SUCCESS: 'GET_CLAIM_FILTERS_SUCCESS',
    GET_CLAIM_FILTERS_FAILURE: 'GET_CLAIM_FILTERS_FAILURE',

    SAVE_FORM_REQUEST: 'SAVE_FORM_REQUEST',
    SAVE_FORM_SUCCESS: 'SAVE_FORM_SUCCESS',
    SAVE_FORM_FAILURE: 'SAVE_FORM_FAILURE',

    GET_CHOICES_DATA_REQUEST: 'GET_CHOICES_DATA_REQUEST',
    GET_CHOICES_DATA_SUCCESS: 'GET_CHOICES_DATA_SUCCESS',
    GET_CHOICES_DATA_FAILURE: 'GET_CHOICES_DATA_FAILURE',

    RESET_UPLOAD_DATA: 'RESET_UPLOAD_DATA',

    GET_FEEDBACK_DASHBOARD_DATA_REQUEST: 'GET_FEEDBACK_DASHBOARD_DATA_REQUEST',
    GET_FEEDBACK_DASHBOARD_DATA_SUCCESS: 'GET_FEEDBACK_DASHBOARD_DATA_SUCCESS',
    GET_FEEDBACK_DASHBOARD_DATA_FAILURE: 'GET_FEEDBACK_DASHBOARD_DATA_FAILURE',

    GET_FEEDBACK_LIST_DATA_REQUEST: 'GET_FEEDBACK_LIST_DATA_REQUEST',
    GET_FEEDBACK_LIST_DATA_SUCCESS: 'GET_FEEDBACK_LIST_DATA_SUCCESS',
    GET_FEEDBACK_LIST_DATA_FAILURE: 'GET_FEEDBACK_LIST_DATA_FAILURE',

    GENERATE_SIGNED_URL_REQUEST: 'GENERATE_SIGNED_URL_REQUEST',
    GENERATE_SIGNED_URL_SUCCESS: 'GENERATE_SIGNED_URL_SUCCESS',
    GENERATE_SIGNED_URL_FAILURE: 'GENERATE_SIGNED_URL_FAILURE',

    CLAIMS_FILE_UPLOAD_REQUEST: 'CLAIMS_FILE_UPLOAD_REQUEST',
    CLAIMS_FILE_UPLOAD_SUCCESS: 'CLAIMS_FILE_UPLOAD_SUCCESS',
    CLAIMS_FILE_UPLOAD_FAILURE: 'CLAIMS_FILE_UPLOAD_FAILURE',

    GET_SERVICE_CENTER_LIST_REQUEST: 'GET_SERVICE_CENTER_LIST_REQUEST',
    GET_SERVICE_CENTER_LIST_SUCCESS: 'GET_SERVICE_CENTER_LIST_SUCCESS',
    GET_SERVICE_CENTER_LIST_FAILURE: 'GET_SERVICE_CENTER_LIST_FAILURE',


    getClaimList: payload => ({
      type: actions.GET_CLAIM_LIST_REQUEST,
      payload
    }),
    getClaimDetails: payload => ({
      type: actions.GET_CLAIM_DETAIL_REQUEST,
      payload
    }),
    emailClaimList: payload => ({
      type: actions.CLAIM_EMAIL_LIST_REQUEST,
      payload
    }),
    getClaimDisbursementData: payload => ({
      type: actions.GET_CLAIM_DASHBOARD_DATA_REQUEST,
      payload
    }),
    triggerClaimEvent: payload => ({
      type: actions.TRIGGER_CLAIM_EVENT_REQUEST,
      payload
    }),
    triggerClaimSalvageEvent: payload => ({
      type: actions.TRIGGER_CLAIM_SALVAGE_EVENT_REQUEST,
      payload
    }),
    triggerClaimAttributeEvent: payload => ({
      type: actions.TRIGGER_CLAIM_ATTRIBUTE_EVENT_REQUEST,
      payload
    }),
    getClaimFilters: payload => ({
      type: actions.GET_CLAIM_FILTERS_REQUEST,
      payload
    }),
    saveForm: payload => ({
      type: actions.SAVE_FORM_REQUEST,
      payload
    }),
    resetUploadData: () => ({
      type: actions.RESET_UPLOAD_DATA
    }),
    getChoicesData: payload => ({
      type: actions.GET_CHOICES_DATA_REQUEST,
      payload
    }),
    getFeedbackDashboardData: payload => ({
      type: actions.GET_FEEDBACK_DASHBOARD_DATA_REQUEST,
      payload
    }),
    getFeedbackListData: payload => ({
      type: actions.GET_FEEDBACK_LIST_DATA_REQUEST,
      payload
    }),
    generateSignedUrl: payload => ({
      type: actions.GENERATE_SIGNED_URL_REQUEST,
      payload
    }),
    claimsFileUpload: payload => ({
        type: actions.CLAIMS_FILE_UPLOAD_REQUEST,
        payload 
    }),
    getServiceCenterList: payload =>({
      type: actions.GET_SERVICE_CENTER_LIST_REQUEST,
      payload
    })
  }
  export default actions
  