import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { Form, Input, Row, Col, Button, notification } from 'antd'

import UserAction from '../../../redux/user/action'
import LoadingAction from '../../../../redux/loading/actions'
import ErrorAction from '../../../../redux/error/actions'
import { ResetPasswordWrapper } from './index.style'
import { queryStringParser } from '../../../../helpers/queryToObject'

const FormItem = Form.Item

const { resetLoadingRedux } = LoadingAction
const { resetErrorRedux } = ErrorAction
const { resetPassword } = UserAction

class ResetPassword extends Component {
  componentDidMount() {
    const { match } = this.props
    const { uuid } = match.params
    if (!uuid) {
      this.props.history.replace('/')
    }
    this.props.resetLoadingRedux(['RESET_PASSWORD'])
    this.props.resetErrorRedux(['RESET_PASSWORD'])
  }

  componentWillReceiveProps(nextProps) {
    const { resetPasswordLoading, resetPasswordError, resetPasswordMessage } = nextProps
    if (resetPasswordLoading === false) {
      notification[resetPasswordError ? 'error' : 'success']({ message: resetPasswordMessage })
      if (resetPasswordError === false) {
        this.props.history.push('/')
      }
      this.props.resetLoadingRedux(['RESET_PASSWORD'])
      this.props.resetErrorRedux(['RESET_PASSWORD'])
    }
  }

  handleFinish = ({ new_password: newPassword, confirm_password: confirmPassword }) => {
    const { match, location } = this.props
    const queryParams = queryStringParser(location.search)

    if (newPassword !== confirmPassword) {
      return notification['error']({ message: "Passwords doesn't match" })
    }
    const { uuid } = match.params
    this.props.resetPassword({
      body: {
        new_password: newPassword,
        confirm_password: confirmPassword,
        email: get(queryParams, 'email'),
        uuid
      }
    })
  }

  render() {
    return (
      <ResetPasswordWrapper>
        <div className='inner-container'>
          <div className='right-container'>
            <div className='header'>
              INSURANCE PANEL
            </div>
            <div className='forgot-heading'>Reset Password?</div>
            <div className='forgot-subheading'>Enter new password and confirm it.</div>
            <Form onFinish={this.handleFinish}>
              <FormItem
                name='new_password'
                rules={[
                  { required: true, message: 'Password cannot be empty' },
                  {
                    pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/g),
                    message: "Password does not match minimum validation"
                  }
                ]}
              >
                <Input placeholder='Password' type='password' size='large' />
              </FormItem>
              <FormItem
                name='confirm_password'
                rules={[
                  { required: true, message: 'Password cannot be empty' }
                ]}
              >
                <Input placeholder='Confirm Password' type='password' size='large' />
              </FormItem>
              <Row justify='end' align='middle'>
                <Col>
                  <Button type="primary" htmlType="submit" className='sign-button'>
                    RESET PASSWORD
                  </Button>
                </Col>
              </Row>
            </Form>
            <div>
              <div className='password-heading'>Password Rules:</div>
              <ul>
                <li>Password should be alphanumeric, containing at least an upper case character and a special symbol.</li>
                <li>Password should be have minimum 8 characters.</li>
                <li>Password should not contain any sub-strings from the email/username of the user.</li>
                <li>Last and new password should not be same.</li>
              </ul>
            </div>
          </div>
        </div>
      </ResetPasswordWrapper>
    )
  }
}

export default connect(state => ({
  resetPasswordLoading: state.Loading.RESET_PASSWORD,
  resetPasswordError: state.Error.RESET_PASSWORD,
  resetPasswordMessage: state.User.resetPasswordMessage
}), {
  resetLoadingRedux,
  resetErrorRedux,
  resetPassword
})(ResetPassword)
