import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form, Row, InputNumber, notification } from 'antd'
import { get } from 'lodash'

import { VerifyOTPStyle } from './component.style'
import UserActions from '../../../../containers/redux/user/action'
import loadingActions from '../../../../redux/loading/actions'
import errorActions from '../../../../redux/error/actions'

const { resetLoadingRedux } = loadingActions
const { resetErrorRedux } = errorActions
const { verifyOTPAndAddPhone, resendUserOTP, verifyUserOTP, disable2FA } = UserActions

const FormItem = Form.Item

class VerifyOTP extends Component {
  constructor(props) {
    super(props)
    this.state = {
      time: {},
      seconds: 30,
      visible: false
    }
    this.timer = 0
  }

  componentDidMount() {
    const timeLeftVar = this.secondsToTime(this.state.seconds)
    this.setState({ time: timeLeftVar, visible: this.props.visible })
    this.startTimer()
    this.props.resetLoadingRedux(['RESEND_USER_OTP'])
    this.props.resetErrorRedux(['RESEND_USER_OTP'])
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      visible,
      resendOtpLoading, resendOtpError, resendOtpMessage
    } = nextProps

    if (resendOtpLoading === false) {
      notification[resendOtpError ? 'error' : 'success']({ message: resendOtpMessage })
      if (resendOtpError === false) {
        this.timer = 0
        this.setState({ seconds: 30 }, () => this.startTimer())
      }
      this.props.resetLoadingRedux(['RESEND_USER_OTP'])
      this.props.resetErrorRedux(['RESEND_USER_OTP'])
    }

    if (visible !== this.state.visible) {
      this.setState({ visible })
      this.props.form.resetFields()
    }
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000)
    }
  }

  secondsToTime(secs) {
    const hours = Math.floor(secs / (60 * 60))

    const divisorMinutes = secs % (60 * 60)
    const minutes = Math.floor(divisorMinutes / 60)

    const divisorSeconds = divisorMinutes % 60
    const seconds = Math.ceil(divisorSeconds)

    const obj = {
      h: hours,
      m: minutes,
      s: seconds
    }
    return obj
  }

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    const seconds = this.state.seconds - 1
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    })

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer)
    }
  }

  handleSubmit = values => {
    const { sendSMSOtpData, update2FAMethodInProcess, selected2FAmethod, disable2FAInProcesss } = this.props
    if (update2FAMethodInProcess) {
      this.props.verifyUserOTP({
        body: {
          ...values,
          otp_type: selected2FAmethod,
          operation_id: get(sendSMSOtpData, 'operation_id')
        }
      })
    } else if (disable2FAInProcesss) {
      this.props.disable2FA({
        body: {
          ...values,
          otp_type: selected2FAmethod,
          operation_id: get(sendSMSOtpData, 'operation_id')
        }
      })
    } else {
      this.props.verifyOTPAndAddPhone({
        body: {
          ...values,
          operation_id: get(sendSMSOtpData, 'operation_id')
        }
      })
    }
  }

  handleResendOTP = () => {
    const { sendSMSOtpData = {}, selected2FAmethod } = this.props
    this.props.resendUserOTP({
      body: {
        operation_id: get(sendSMSOtpData, 'operation_id'),
        medium: selected2FAmethod === 'EMAIL_OTP' ? 'email' : undefined
      }
    })
  }

  getHeadingText = () => {
    const { selected2FAmethod } = this.props
    switch(selected2FAmethod) {
      case 'EMAIL_OTP':
        return 'Enter OTP received on your Email to proceed'
      case 'SMS_OTP':
        return 'Enter OTP reeceived on SMS to proceed'
      case 'TOTP':
        return 'Enter OTP received on your Authenticator app to proceed'
      default:
        return ''
    }
  }

  render() {
    const { verifyAndAddNumberLoading, resendOtpLoading, update2FAMethodInProcess, verifyUserOTPLoading, selected2FAmethod } = this.props
    const { time } = this.state

    return (
      <VerifyOTPStyle twoFAMethodTOTP={update2FAMethodInProcess && selected2FAmethod === 'TOTP'}>
        <div className='heading'>
          {this.getHeadingText()}
        </div>
        <Form onFinish={this.handleSubmit}>
          <FormItem
            name='otp'
            label='Enter OTP'
            rules={[{ required: true, message: 'OTP cannot be empty' }]}
          >
            <InputNumber />
          </FormItem>
          <Row type='flex' justify='space-between'>
            {
              ((update2FAMethodInProcess && selected2FAmethod !== 'TOTP') || !update2FAMethodInProcess) &&
              <Button
                className='resend-otp-button'
                type='primary'
                onClick={this.handleResendOTP}
                loading={!!resendOtpLoading}
                disabled={time.s > 0}
              >
                Resend OTP {time.s > 0 && `(${time.s} sec)`}
              </Button>
            }
            <Button
              type='primary'
              className='verify-otp-button'
              htmlType='submit'
              loading={!!verifyAndAddNumberLoading || !!verifyUserOTPLoading}
            >
              Verify OTP
            </Button>
          </Row>
        </Form>
      </VerifyOTPStyle>
    )
  }
}

export default connect(state => ({
  sendSMSOtpData: state.User.sendSMSOtpData,

  verifyAndAddNumberLoading: state.Loading.VERIFY_AND_ADD_PHONE,
  verifyUserOTPLoading: state.Loading.VERIFY_USER_OTP,

  resendOtpLoading: state.Loading.RESEND_USER_OTP,
  resendOtpError: state.Error.RESEND_USER_OTP,
  resendOtpMessage: state.User.resendUserOTPMessage
}), {
  verifyOTPAndAddPhone,
  resendUserOTP,
  verifyUserOTP,
  disable2FA,
  resetLoadingRedux,
  resetErrorRedux
})(VerifyOTP)
