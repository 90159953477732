import React, { Component, lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

const routes = [
  {
    path: '',
    component: lazy(() => import('../Pages/Private/Dashboard')),
    requiredConfig: 'DISPLAY_POLICY_DASHBOARD',
    requiredPermission: 'view_dashboard'
  }, {
    path: 'policy',
    component: lazy(() => import('../Pages/Private/Policy')),
    requiredConfig: 'DISPLAY_POLICY_LIST',
    requiredPermission: 'view_policy_list'
  }, {
    path: 'policy/:id',
    component: lazy(() => import('../Pages/Private/Policy/Details')),
    requiredConfig: 'DISPLAY_POLICY_DETAILS',
    requiredPermission: 'view_policy_details'
  }, {
    path: 'claim',
    component: lazy(() => import('../Pages/Private/Claim')),
    requiredConfig: 'DISPLAY_CLAIM_LIST',
    requiredPermission: 'view_claim_list'
  }, {
    path: 'claim/:id',
    component: lazy(() => import('../Pages/Private/Claim/Details')),
    requiredConfig: 'DISPLAY_CLAIM_DETAILS',
    requiredPermission: 'view_claim_details'
  }, {
    path: 'customer-feedback',
    component: lazy(() => import('../Pages/Private/CustomerFeedback')),
    requiredConfig: 'DISPLAY_CUSTOMER_FEEDBACK',
    requiredPermission: 'view_customer_feedback'
  }, {
    path: 'customer-feedback/list',
    component: lazy(() => import('../Pages/Private/CustomerFeedback/components/feedbackList')),
    requiredConfig: 'DISPLAY_CUSTOMER_FEEDBACK',
    requiredPermission: 'view_customer_feedback'
  }, {
    path: 'user-management',
    component: lazy(() => import('../Pages/Private/UserManagement')),
    requiredConfig: 'DISPLAY_USER_MANAGEMENT',
    requiredPermission: 'view_user_management'
  }, {
    path: 'guideline',
    component: lazy(() => import('../Pages/Private/Guideline'))
  }
]

class AppRouter extends Component {

  configLookUp = (value) => {
    let { config = [] } = this.props
    if (!config.length) {
      config = JSON.parse(localStorage.getItem('config'))
    }
    return Array.isArray(config) && config.findIndex(x => (x.config_type || {}).name === value && x.value === '1') > -1
  }

  permissionLookUp = (value) => {
    let { permissions = [] } = this.props
    if (!permissions.length) {
      permissions = JSON.parse(localStorage.getItem('permissions'))
    }
    return Array.isArray(permissions) && permissions.findIndex(x => (x.codename === value)) > -1
  }

  getRedirectionPath = () => {
    if (this.configLookUp('DISPLAY_POLICY_DASHBOARD') && this.permissionLookUp('view_dashboard')) {
      return '/dashboard'
    }
    if (this.configLookUp('DISPLAY_POLICY_LIST') && this.permissionLookUp('view_policy_list')) {
      return '/policy'
    }
    if (this.configLookUp('DISPLAY_CLAIM_LIST') && this.permissionLookUp('view_claim_list')) {
      return '/claim'
    }
    return ''
  }

  render() {
    const { url } = this.props

    return (<div>
      <Suspense fallback={<div />}>
        <Switch>
          {
            routes.map(({ component, exact, requiredConfig, requiredPermission, path }) => {
              return (<Route exact={exact !== false} key={path} path={`${url}${path}`} render={(props) => {
                let hasRequiredConfig = requiredConfig
                  ? this.configLookUp(requiredConfig)
                  : true
                let hasRequiredPermissions = requiredPermission
                  ? this.permissionLookUp(requiredPermission)
                  : true
                if (hasRequiredConfig && hasRequiredPermissions) {
                  return React.createElement(component, {
                    ...props
                  })
                } else {
                  return <Redirect to={this.getRedirectionPath()} />
                }
              }}
              />)
            })
          }
          <Redirect to='/' />
        </Switch>
      </Suspense>
    </div>)
  }
}

AppRouter.propTypes = {
  permissions: PropTypes.array.isRequired,
  config: PropTypes.array.isRequired,
  group: PropTypes.array.isRequired
}

AppRouter.defaultProps = {
  permissions: [],
  config: [],
  group: []
}

export default AppRouter
