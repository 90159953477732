import styled from "styled-components"
import { Layout } from 'antd'
import { APP_COLORS } from "../../constants/colors"

const { Header } = Layout

export const TopbarWrapper = styled.div`
  .ant-layout-header {
    background-color: #fff;
  }
`

export const DrawerTopDiv = styled.div`
`

export const DrawerStyle = styled.div`
`

export const WebTopBarStyleWrapper = styled(Header)`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: space-between;
  .logo-image {
    float: left;
  }
  .ant-drawer-header{
    margin:5%;
  }
  .logo {
    padding: 5px;
    max-height: 38px;
  }
  .topbar-container {
    width: 100%;
  }
  .ant-menu-item-active {
    font-weight: 600;
  }
  @media screen and (max-width: 800px) {
    .topbar-container {
      width: 50%;
    }
  }
`


export const UserProfileStyle = styled.div`
  .heading {
    font-weight: 600;
  }
  .ant-row {
    margin-bottom: 8px;
  }
  .change-icon {
    width: 20px;
    margin-left: 4px;
    cursor: pointer;
  }
`

export const SecurityStyle = styled.div`
  .heading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .security-option-container {
    border: 1px solid #eee;
    padding: 12px;
    margin-top: 14px;
    cursor: pointer;
  }
  .security-heading {
    font-weight: 600;
    font-size: 14px;
  }
  .sub-security-heading {
    color: #a9a9a9;
    width: 100%;
    font-size: 13px;
  }
  .update-button {
    margin-top: 20px;
  }
  .disabled {
    cursor: not-allowed;
  }
  .setup-option {
    color: #1890ff;
    font-weight: bold;
    margin-left: 4px;
    cursor: pointer;
  }
  .ant-btn-primary {
    background-color: ${APP_COLORS.primary};
    border-color: ${APP_COLORS.primary};
  }
  .switch-containers {
    margin-bottom: 20px;
  }
`
