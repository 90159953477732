import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import { get } from 'lodash'

import actions from './action'
import noAuthpost from '../../../request/methods/post'
import authGet from '../../../request/methods/authGet'
import authPost from '../../../request/methods/authPost'
import userUrls from '../../../request/endpoint/user'
import appActions from '../../../redux/app/actions'

export function* forgotPassword() {
  yield takeEvery(actions.FORGOT_PASSWORD_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(noAuthpost, { ...payload, url: userUrls.forgotPassword })
      if (res.status === 200) {
        yield put({
          type: actions.FORGOT_PASSWORD_SUCCESS,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.FORGOT_PASSWORD_FAILURE,
          message: get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.FORGOT_PASSWORD_FAILURE,
        message: get(e.data, 'message', 'Error')
      })
    }
  })
}

export function* resetPasswordRequest() {
  yield takeEvery(actions.RESET_PASSWORD_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(noAuthpost, { ...payload, url: userUrls.resetPassword })
      if (res.status === 200) {
        yield put({
          type: actions.RESET_PASSWORD_SUCCESS,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.RESET_PASSWORD_FAILURE,
          message: get(res.data, 'message', 'Reset Password Failed')
        })
      }
    } catch (e) {
      yield put({
        type: actions.RESET_PASSWORD_FAILURE,
        message: get(e.data, 'message', 'Reset Password Failed')
      })
    }
  })
}

export function * sendSMSOTP () {
  yield takeEvery(actions.SEND_SMS_OTP_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { ...payload, url: userUrls.sendSMSOTP })
      if (res.status === 200) {
        yield put({
          type: actions.SEND_SMS_OTP_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'OTP sent!')
        })
      } else {
        yield put({
          type: actions.SEND_SMS_OTP_FAILURE,
          data: {},
          message: get(res.data, 'message', 'OTP sending failed!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.SEND_SMS_OTP_FAILURE,
        data: {},
        message: get(e.data, 'message', 'OTP sending failed!')
      })
    }
  })
}

export function * verifyAndAddPhone () {
  yield takeEvery(actions.VERIFY_AND_ADD_PHONE_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { ...payload, url: userUrls.verifyOTPAndAddPhone })
      if (res.status === 200) {
        yield put({
          type: actions.VERIFY_AND_ADD_PHONE_SUCCESS,
          message: get(res.data, 'message', 'Phone Number Updated!')
        })
        yield put({
          type: appActions.GET_CONFIG_REQUEST
        })
      } else {
        yield put({
          type: actions.VERIFY_AND_ADD_PHONE_FAILURE,
          message: get(res.data, 'message', 'Update Phone Number Failed!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.VERIFY_AND_ADD_PHONE_FAILURE,
        message: get(e.data, 'message', 'Update Phone Number Failed!')
      })
    }
  })
}

export function * resendUserOTP () {
  yield takeEvery(actions.RESEND_USER_OTP_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { ...payload, url: userUrls.resendUserOTP })
      if (res.status === 200) {
        yield put({
          type: actions.RESEND_USER_OTP_SUCCESS,
          message: get(res.data, 'message', 'OTP resent successfully!')
        })
      } else {
        yield put({
          type: actions.RESEND_USER_OTP_FAILURE,
          message: get(res.data, 'message', 'OTP resent failed!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.RESEND_USER_OTP_FAILURE,
        message: get(e.data, 'message', 'OTP resent failed!')
      })
    }
  })
}

export function * getTOTPSharedSecret () {
  yield takeEvery(actions.GET_TOTP_SHARED_SECRET_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authGet, { ...payload, url: userUrls.totpSharedSecret })
      if (res.status === 200) {
        yield put({
          type: actions.GET_TOTP_SHARED_SECRET_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success!')
        })
      } else {
        yield put({
          type: actions.GET_TOTP_SHARED_SECRET_FAILURE,
          data: {},
          message: get(res.data, 'error', 'Error!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_TOTP_SHARED_SECRET_FAILURE,
        data: {},
        message: get(e.data, 'error', 'Error!')
      })
    }
  })
}

export function * verifyTOTP () {
  yield takeEvery(actions.VERIFY_TOTP_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { ...payload, url: userUrls.verifyTOTP })
      if (res.status === 200) {
        yield put({
          type: actions.VERIFY_TOTP_SUCCESS,
          message: get(res.data, 'message', 'Device added successfully!')
        })
        yield put({
          type: appActions.GET_CONFIG_REQUEST
        })
      } else {
        yield put({
          type: actions.VERIFY_TOTP_FAILURE,
          message: get(res.data, 'error', 'OTP verification failed!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.VERIFY_TOTP_FAILURE,
        message: get(e.data, 'error', 'OTP verification failed!')
      })
    }
  })
}

export function * update2FAMethod () {
  yield takeEvery(actions.UPDATE_2FA_METHOD_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { ...payload, url: userUrls.update2FAMethod })
      if (res.status === 200) {
        yield put({
          type: actions.UPDATE_2FA_METHOD_SUCCESS,
          message: get(res.data, 'message', '2FA method updated successfully!')
        })
        yield put({
          type: appActions.GET_CONFIG_REQUEST
        })
      } else {
        yield put({
          type: actions.UPDATE_2FA_METHOD_FAILURE,
          message: get(res.data, 'error', '2FA method updation failed!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPDATE_2FA_METHOD_FAILURE,
        message: get(e.data, 'error', '2FA method updation failed!')
      })
    }
  })
}

export function * verifyUserOTP () {
  yield takeEvery(actions.VERIFY_USER_OTP_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { ...payload, url: userUrls.verifyUserOTP })
      if (res.status === 200) {
        yield put({
          type: actions.VERIFY_USER_OTP_SUCCESS,
          message: get(res.data, 'message', 'OTP verified!')
        })
      } else {
        yield put({
          type: actions.VERIFY_USER_OTP_FAILURE,
          message: get(res.data, 'error', 'OTP verification failed!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.VERIFY_USER_OTP_FAILURE,
        message: get(e.data, 'error', 'OTP verification failed!')
      })
    }
  })
}

export function * disable2FA () {
  yield takeEvery(actions.DISABLE_2FA_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { ...payload, url: userUrls.disable2FA })
      if (res.status === 200) {
        yield put({
          type: actions.DISABLE_2FA_SUCCESS,
          message: get(res.data, 'message', '2FA disabled sucessfully!')
        })
      } else {
        yield put({
          type: actions.DISABLE_2FA_FAILURE,
          message: get(res.data, 'error', '2FA Disable Failed!')
        })
      }
    } catch (e) {
      yield put({
        type: actions.DISABLE_2FA_FAILURE,
        message: get(e.data, 'error', '2FA Disable Failed!')
      })
    }
  })
}


export default function* rootSaga() {
  yield all([
    fork(forgotPassword),
    fork(resetPasswordRequest),
    fork(sendSMSOTP),
    fork(verifyAndAddPhone),
    fork(resendUserOTP),
    fork(getTOTPSharedSecret),
    fork(verifyTOTP),
    fork(update2FAMethod),
    fork(verifyUserOTP),
    fork(disable2FA)
  ])
}
