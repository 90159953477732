export function getView(width) {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}
const actions = {
  GET_CONFIG_REQUEST: 'GET_CONFIG_REQUEST',
  GET_CONFIG_SUCCESS: 'GET_CONFIG_SUCCESS',
  GET_CONFIG_FAILURE: 'GET_CONFIG_FAILURE',

  getConfig: () => ({
    type: actions.GET_CONFIG_REQUEST
  })
};
export default actions;
