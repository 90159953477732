const BASE_URL = process.env.REACT_APP_API_ENDPOINT
const BASE_PATH = '/api/v1/user/'
const BASE_AUTH_PATH = '/api/v1/auth/'

export default {
  getUserList: `${BASE_URL}${BASE_AUTH_PATH}user-list/`,
  getGroupList: `${BASE_URL}${BASE_AUTH_PATH}group-list/`,
  addUser: `${BASE_URL}${BASE_AUTH_PATH}add-user/`,
  editUser: `${BASE_URL}${BASE_AUTH_PATH}edit-user/`,
  updateStatus: `${BASE_URL}${BASE_AUTH_PATH}edit-status/`,
}
