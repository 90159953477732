import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import { get } from 'lodash'
import actions from './action'
import authGet from '../../../request/methods/authGet'
import guidelineUrls from '../../../request/endpoint/guideline'

export function* getGuidelineList() {
  yield takeEvery(actions.GET_GUIDELINE_LIST_REQUEST, function* () {
    try {
      let res = yield call(authGet, { url: guidelineUrls.getGuidelineData })
      if (res.status === 200) {
        yield put({
          type: actions.GET_GUIDELINE_LIST_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_GUIDELINE_LIST_FAILURE,
          data: [],
          message: get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_GUIDELINE_LIST_FAILURE,
        data: [],
        message: get(e.data, 'message', 'Error')
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getGuidelineList)
  ])
}
