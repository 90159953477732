import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button, Row, Col, notification } from 'antd'

import UserAction from '../../../redux/user/action'
import LoadingAction from '../../../../redux/loading/actions'
import ErrorAction from '../../../../redux/error/actions'
import { ForgotPasswordWrapper } from './index.style'
import { regExp } from '../../../../helpers/utility'

const FormItem = Form.Item

const { resetLoadingRedux } = LoadingAction
const { resetErrorRedux } = ErrorAction
const { forgotPassword } = UserAction

class ForfotPassword extends Component {
  componentDidMount() {
    this.props.resetLoadingRedux(['FORGOT_PASSWORD'])
    this.props.resetErrorRedux(['FORGOT_PASSWORD'])
  }

  componentWillReceiveProps(nextProps) {
    const {
      forgotPasswordLoading,
      forgotPasswordError,
      forgotPasswardMessage
    } = nextProps
    if (forgotPasswordLoading === false) {
      notification[forgotPasswordError ? 'error' : 'success']({
        message: forgotPasswardMessage
      })
      this.props.resetLoadingRedux(['FORGOT_PASSWORD'])
      this.props.resetErrorRedux(['FORGOT_PASSWORD'])
    }
  }

  handleLoginClick = () => {
    this.props.history.push('/')
  }

  handleFinish = (values) => {
    this.props.forgotPassword({
      body: {
        ...values
      }
    })
  }

  render() {
    return (
      <ForgotPasswordWrapper>
        <div className='inner-container'>
          <div className='right-container'>
            <div className='header'>INSURANCE PANEL</div>
            <div className='forgot-heading'>Forgot Password?</div>
            <div className='forgot-subheading'>
              Enter your email and we will send you a reset link.
            </div>
            <Form onFinish={this.handleFinish}>
              <FormItem
                name='email'
                rules={[
                  {
                    required: true,
                    pattern: regExp.email,
                    message: 'Enter a valid email address'
                  }
                ]}
              >
                <Input placeholder='Email' size='large' />
              </FormItem>
              <Row justify='space-between' align='middle'>
                <Col className='faded-text' onClick={this.handleLoginClick}>
                  Back to Login
                </Col>
                <Col>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='sign-button'
                  >
                    SEND REQUEST
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ForgotPasswordWrapper>
    )
  }
}

export default connect(
  (state) => ({
    forgotPasswordLoading: state.Loading.FORGOT_PASSWORD,
    forgotPasswordError: state.Error.FORGOT_PASSWORD,
    forgotPasswardMessage: state.User.forgotPasswardMessage
  }),
  {
    resetLoadingRedux,
    resetErrorRedux,
    forgotPassword
  }
)(ForfotPassword)
