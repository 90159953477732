const actions = {
    GET_USER_LIST_REQUEST: 'GET_USER_LIST_REQUEST',
    GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
    GET_USER_LIST_FAILURE: 'GET_USER_LIST_FAILURE',
  
    GET_GROUP_LIST_REQUEST: 'GET_GROUP_LIST_REQUEST',
    GET_GROUP_LIST_SUCCESS: 'GET_GROUP_LIST_SUCCESS',
    GET_GROUP_LIST_FAILURE: 'GET_GROUP_LIST_FAILURE',
  
    ADD_USER_REQUEST: 'ADD_USER_REQUEST',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILURE: 'ADD_USER_FAILURE',
  
    EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
    EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
    EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',
  
    UPDATE_USER_STATUS_REQUEST: 'UPDATE_USER_STATUS_REQUEST',
    UPDATE_USER_STATUS_SUCCESS: 'UPDATE_USER_STATUS_SUCCESS',
    UPDATE_USER_STATUS_FAILURE: 'UPDATE_USER_STATUS_FAILURE',
  
    getUserList: () => ({
      type: actions.GET_USER_LIST_REQUEST
    }),
    getGroupList: () => ({
      type: actions.GET_GROUP_LIST_REQUEST
    }),
    addUser: payload => ({
      type: actions.ADD_USER_REQUEST,
      payload
    }),
    editUser: payload => ({
      type: actions.EDIT_USER_REQUEST,
      payload
    }),
    updateUserStatus: payload => ({
      type: actions.UPDATE_USER_STATUS_REQUEST,
      payload
    })
  }
  export default actions
  