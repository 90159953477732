import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'

class ConfigChecker extends Component {
  render() {
    const { providerDetails = {}, requiredConfig, requiredValue } = this.props
    const partnerConfigs = get(providerDetails, 'config', [])
    if (!!partnerConfigs.find(({
      config_type = {},
      value
    }) => (value === requiredValue && config_type.name === requiredConfig))) {
      return this.props.children
    }
    return null
  }
}

ConfigChecker.propTypes = {
  partnerDetails: PropTypes.object.isRequired,
  requiredConfig: PropTypes.string.isRequired
}

ConfigChecker.defaultProps = {
  partnerDetails: {}
}

export default connect(state => ({
  providerDetails: state.App.providerDetails
}), {})(ConfigChecker)
