import styled from 'styled-components'
import { APP_COLORS } from '../../constants/colors'

export const AppStyle = styled.div`
  font-family: Muli, sans-serif !important;
  .footer-image {
      margin-left: 4px;
  }
  .ant-layout-content {
    min-height: 80vh;
    padding: 50px 30px;
    max-width: 100vw;
  }
  .ant-btn-primary {
    background-color: ${APP_COLORS.primary};
    border-color: ${APP_COLORS.primary};
  }
  .ant-table {
    overflow-x: auto;
  }
  .ant-table-thead > tr > th {
    background-color: ${APP_COLORS.primary};
    color: #fff;
    font-size: 13px;
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .ant-table-tbody {
    color: #797979;
    font-size: 12px;
    border-bottom: 1px solid #e9e9e9;
  }
  .ant-table-tbody > tr > td {
    padding: 16px 15px;
    white-space: nowrap;
    text-align: left;
  }
  .ant-pagination-item-active a {
    background-color: ${APP_COLORS.primary};
    border-color: ${APP_COLORS.primary};
    color: #fff;
  }
  .ant-spin-dot-item {
    background-color: ${APP_COLORS.primary};
  }
  .footer {
    background-color: #fff;
    text-align: center;
    border-top: 1px solid #ededed;
    display: flex;
    justify-content: center;
  }
  .layout {
    flex-direction: row;
    overflow-x: hidden;
  }
  .ant-table-thead > tr > th {
    background-color: ${APP_COLORS.primary};
    color: #fff;
  }
`
