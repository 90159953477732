const BASE_URL = process.env.REACT_APP_API_ENDPOINT
const BASE_PATH = '/api/v1/claims/'
const BASE_PATH_V2 = '/api/v2/claims/'

export default {
  getClaimList: `${BASE_URL}${BASE_PATH}list/`,
  claimDetailUrl: `${BASE_URL}${BASE_PATH_V2}details/`,
  emailList: `${BASE_URL}${BASE_PATH}email-list/`,
  getClaimDashboardData: `${BASE_URL}${BASE_PATH}dashboard/`,
  triggerClaimEvent: `${BASE_URL}${BASE_PATH_V2}trigger-event/`,
  triggerClaimSalvageEvent: `${BASE_URL}${BASE_PATH_V2}salvage/salvage-trigger/`,
  getClaimFilters: `${BASE_URL}${BASE_PATH}filters/`,
  saveForm: `${BASE_URL}${BASE_PATH}save-form/`,
  getChoicesData: `${BASE_URL}${BASE_PATH}get-choices-data/`,
  getFeedbackDashboard: `${BASE_URL}${BASE_PATH}feedback-dashboard/`,
  getFeedbackList: `${BASE_URL}${BASE_PATH}feedback-list/`,
  generateSignedUrl: `${BASE_URL}${BASE_PATH}generate-signed-url/`,
  getServiceCenterList: `${BASE_URL}${BASE_PATH}list-service-center/`,
}
