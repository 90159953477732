const actions = {

    NOTIFICATIONS_LIST_REQUEST: 'NOTIFICATIONS_LIST_REQUEST',
    NOTIFICATIONS_LIST_SUCCESS: 'NOTIFICATIONS_LIST_SUCCESS',
    NOTIFICATIONS_LIST_FAILURE: 'NOTIFICATIONS_LIST_FAILURE',

    getNotificationsList: (payload) => ({
      type: actions.NOTIFICATIONS_LIST_REQUEST,
      payload
    })
  
  }
  export default actions
  