import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form, Row, notification, InputNumber, Spin } from 'antd'
import { get } from 'lodash'
import QRCode from 'qrcode.react'

import { AddTOTPDeviceStyle } from './component.style'
import UserActions from '../../../../containers/redux/user/action'
import loadingActions from '../../../../redux/loading/actions'
import errorActions from '../../../../redux/error/actions'

const { resetLoadingRedux } = loadingActions
const { resetErrorRedux } = errorActions
const { getTOTPSharedSecret, verifyTOTP } = UserActions

const FormItem = Form.Item

class AddTOTPDevice extends Component {
  state = {
    visible: false
  }

  componentDidMount() {
    this.props.getTOTPSharedSecret()
    this.setState({ visible: this.props.visible })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      totpSharedSecretLoading, totpSharedSecretError, totpSharedSecretMessage
    } = nextProps

    if (totpSharedSecretLoading === false && totpSharedSecretError) {
      notification.error({ message: totpSharedSecretMessage })
      this.props.resetLoadingRedux(['GET_TOTP_SHARED_SECRET'])
      this.props.resetErrorRedux(['GET_TOTP_SHARED_SECRET'])
    }
  }

  handleSubmit = values => {
    this.props.verifyTOTP({
      body: values
    })
  }

  render() {
    const { totpSharedSecretLoading, totpSharedSecret = {}, verifyTOTPLoading } = this.props

    return (
      <Spin spinning={!!totpSharedSecretLoading}>
        <AddTOTPDeviceStyle>
          <div>
            <div className='instruction-heading'>
              Complete following steps to configure Time based OTP
            </div>
            <div className='instruction-points'>
              <div>1. Install/Open the authenticator app on your device</div>
              <div>2. Scan the below image</div>
              <div>3. Enter the OTP in the below input box</div>
              <div>4. Click on <b>Verify OTP</b> button</div>
            </div>
          </div>
          <Row type='flex' justify='center' className='qr-code-container'>
            <QRCode className='qr-code' value={get(totpSharedSecret, 'config_url', '')} />
          </Row>
          <Form onFinish={this.handleSubmit}>
            <FormItem label='Enter OTP' name='otp'
              rules={[{
                required: true,
                message: 'OTP is required'
              }
              ]}>
              <InputNumber />
            </FormItem>
            <Row type='flex'>
              <Button
                type='primary'
                className='verify-otp-button'
                htmlType='submit'
                loading={!!verifyTOTPLoading}
              >
                Verify OTP
              </Button>
            </Row>
          </Form>
        </AddTOTPDeviceStyle>
      </Spin>
    )
  }
}

export default connect(state => ({
  userData: state.Auth.profile,

  totpSharedSecret: state.User.totpSharedSecret,
  totpSharedSecretLoading: state.Loading.GET_TOTP_SHARED_SECRET,
  totpSharedSecretError: state.Error.GET_TOTP_SHARED_SECRET,
  totpSharedSecretMessage: state.User.totpSharedSecretMessage,

  verifyTOTPLoading: state.Loading.VERIFY_TOTP
}), {
  getTOTPSharedSecret,
  verifyTOTP,
  resetLoadingRedux,
  resetErrorRedux
})(AddTOTPDevice)
