import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './actions'
import middleware from '../../helpers/responseChecker'
import authGet from '../../request/methods/authGet'
import AppUrls from '../../request/endpoint/app'

export function* getConfig() {
  yield takeEvery(actions.GET_CONFIG_REQUEST, function* ({ payload = {} }) {
    try {
      let res = yield call(authGet, { ...payload, url: AppUrls.getConfig })
      if (res.status === 200) {
        const {
          user_details: userDetails,
          user_permissions: userPermissions,
          provider_details: providerDetails,
          slab
        } = res.data
        yield localStorage.setItem('permissions', JSON.stringify(userPermissions))
        yield localStorage.setItem('config', JSON.stringify(providerDetails.config))

        yield put({
          type: actions.GET_CONFIG_SUCCESS,
          userDetails,
          userPermissions,
          providerDetails,
          slab
        })
      } else {
        middleware(res)
        yield put({
          type: actions.GET_CONFIG_FAILURE,
          data: res.data,
          userDetails: {},
          userPermissions: [],
          providerDetails: {}
        })
      }
    } catch (e) {
      middleware(e)
      yield put({
        type: actions.GET_CONFIG_FAILURE,
        data: e.data,
        userDetails: {},
        userPermissions: [],
        providerDetails: {}
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getConfig)
  ])
}

