import { all } from 'redux-saga/effects'
import authSagas from './auth/saga'
import appSagas from './app/saga'
import errorSagas from './error/saga'
import loadingSagas from './loading/saga'
import userSagas from '../containers/redux/user/saga'
import claimSagas from '../containers/redux/claims/saga'
import policySagas from '../containers/redux/policy/saga'
import notificationSagas from '../containers/redux/notifications/saga'
import userManagementSagas from '../containers/redux/userManagement/saga'
import guidelineSagas from '../containers/redux/guideline/saga'

export default function* rootSaga() {
  yield all([
    authSagas(),
    appSagas(),
    errorSagas(),
    loadingSagas(),
    userSagas(),
    claimSagas(),
    policySagas(),
    notificationSagas(),
    userManagementSagas(),
    guidelineSagas()
  ])
}
