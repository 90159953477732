import { clearToken } from '../helpers/utility';

const responseChecker = err => {
  if (err.response !== undefined) {
    const { status } = err.response
    if (status === 401 || status === 440) {
      clearToken()
      window.location.href = '/signin'
    }
  } else if (err.status === 401 || err.status === 440) {
    clearToken()
    window.location.href = '/signin'
  }
}

export default responseChecker
