export const parseResponseError = (err, initialError) => {
  let errString = ''
  if (err) {
    if (typeof err === 'string') {
      return err
    }
    if (Object.keys(err).length > 0) {
      const values = Object.keys(err).map(function (key) {
        return err[key]
      })
      errString = values.toString()
    }
  }

  return errString || initialError
}
