import actions from './actions'

const initState = {
  idToken: null
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
    case actions.LOGIN_FAILURE:
      return {
        ...state,
        idToken: action.token,
        loginMessage: action.message,
        loginData: action.data
      }
    case actions.LOGOUT_SUCCESS:
      return initState
    case actions.RESEND_OTP_SUCCESS:
    case actions.RESEND_OTP_FAILURE:
      return {
        ...state,
        resendOtpData: action.data
      }
    case actions.LOGOUT_FAILURE:
      return {
        ...state,
        logoutMessage: action.message
      }
    default:
      return state
  }
}
