import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import _ from 'lodash'
import actions from './actions'
import authGet from '../../../request/methods/authGet'
import notifUrls from '../../../request/endpoint/notifications'

export function* getNotificationsList() {
  yield takeEvery(actions.NOTIFICATIONS_LIST_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: notifUrls.getNotifTrace })
      if (res.status === 200) {
        yield put({
          type: actions.NOTIFICATIONS_LIST_SUCCESS,
          data: res.data,
          message: _.get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.NOTIFICATIONS_LIST_FAILURE,
          data: {},
          message: _.get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.NOTIFICATIONS_LIST_FAILURE,
        data: {},
        message: _.get(e.data, 'message', 'Error')
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getNotificationsList)
  ])
}
