export const PhoneNumberPrefix = [
  {
    label: '+62',
    value: '62'
  },
  {
    label: '+66',
    value: '66'
  },
  {
    label: '+84',
    value: '84'
  },
  {
    label: '+60',
    value: '60'
  },
  {
    label: '+65',
    value: '65'
  },
  {
    label: '+63',
    value: '63'
  },
  {
    label: '+886',
    value: '886'
  },
  {
    label: '+86',
    value: '86'
  },
  {
    label: '+852',
    value: '852'
  }
]
