const BASE_URL = process.env.REACT_APP_API_ENDPOINT
const BASE_PATH = '/api/v1/policy/'

export default {
  getDashboardData: `${BASE_URL}${BASE_PATH}dashboard/`,
  getPolicyList: `${BASE_URL}${BASE_PATH}list/`,
  getPolicyListCount: `${BASE_URL}${BASE_PATH}list_count/`,
  emailList: `${BASE_URL}${BASE_PATH}email_list/`,
  emailRangeList: `${BASE_URL}${BASE_PATH}email_list_big_data/`,
  getPolicyDetails: `${BASE_URL}${BASE_PATH}details/`,
  getPartnersList: `${BASE_URL}${BASE_PATH}partners-list/`,
  getProductsList: `${BASE_URL}${BASE_PATH}products-list/`,
  getPackagesList: `${BASE_URL}${BASE_PATH}packages-list/`,
  getChangehistoryUrl: `${BASE_URL}${BASE_PATH}policy-revision/`,
  getPaymentListUrl: `${BASE_URL}${BASE_PATH}payment-list/`
}
