import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Input, Form, Row, Col, notification } from 'antd'
import { get } from 'lodash'

import authAction from '../../../../redux/auth/actions'
import LoadingAction from '../../../../redux/loading/actions'
import ErrorAction from '../../../../redux/error/actions'
import { SignInWrapper } from './index.style'
import { regExp } from '../../../../helpers/utility'

const REACT_APP_CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY

const FormItem = Form.Item

const { resetLoadingRedux } = LoadingAction
const { resetErrorRedux } = ErrorAction
const { login } = authAction

class SignIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirectToReferrer: false,
      loading: false
    }
    this.captcha = React.createRef()
    this.formRef = React.createRef()
  }

  loadRecaptcha = () => {
    if (window?.grecaptcha?.render) {
      const wrapper = document.createElement("div");
      this._widgetId = window.grecaptcha.render(wrapper, {
        sitekey: REACT_APP_CAPTCHA_SITE_KEY,
        callback: this.onSubmit,
      });
      this.captcha.appendChild(wrapper);
    }
  }

  componentDidMount() {
    if (window?.grecaptcha?.render) {
      this.loadRecaptcha()
    } else {
      let attempts = 3
      while (attempts) {
        setTimeout(() => {
          this._widgetId === undefined && this.loadRecaptcha()
        }, 1000 * (4 - attempts))
        attempts--
      }
    }
    if (this.props.isLoggedIn === true) {
      this.setState({ redirectToReferrer: true })
    }
    this.props.resetLoadingRedux(['LOGIN'])
    this.props.resetErrorRedux(['LOGIN'])
  }

  componentDidUpdate() {
    const { loginLoading, loginError } = this.props
    if (!loginLoading && loginError) {
        this.loadRecaptcha()
    }
  }

  componentWillReceiveProps(nextProps) {
    const { loginLoading, loginError, loginMessage, loginData = {} } = nextProps

    if (loginLoading === false) {
      if (loginError === false) {
        this.props.history.push(`/auth`)
      } else {
        this.formRef.current.resetFields(['captcha_response'])
        notification['error']({ message: loginMessage })
        let resetRequestID = get(loginData, 'reset_request_id')
        let userEmail = get(loginData, 'user_email')
        if (resetRequestID) {
          this.props.history.push(`/reset-password/${resetRequestID}?email=${userEmail}`)
        }
      }
      this.props.resetLoadingRedux(['LOGIN'])
      this.props.resetErrorRedux(['LOGIN'])
    }
  }

  handleRecaptchaRef = (elem) => {
    this.captcha = elem;
  }

  handleFinish = (values) => {
    this.props.login({
      body: {
        ...values
      }
    })
  }

  onSubmit = (token) => {
    this.formRef.current.setFieldsValue({'captcha_response': token})
  }

  handleForgotPassword = () => {
    this.props.history.push('/forgot-password')
  }

  render() {
    const { loginLoading } = this.props
    const { redirectToReferrer } = this.state
    const from = { pathname: '/dashboard' }
    if (redirectToReferrer) {
      return <Redirect to={from} />
    }

    return (
      <SignInWrapper className="isoSignInPage">
        <div className='inner-container'>
          <div className='right-container'>
            <div className='header'>
              INSURANCE PANEL
            </div>
            <Form ref={this.formRef} onFinish={this.handleFinish}>
              <FormItem
                name='username'
                rules={[
                  { 
                    required: true, 
                    pattern: regExp.email,
                    message: 'Enter a valid email address'
                  }
                ]}
              >
                <Input placeholder='Email' size='large' />
              </FormItem>
              <FormItem
                name='password'
                rules={[{ required: true, message: 'Password cannot be empty' }]}
              >
                <Input placeholder='Password' type='password' size='large' />
              </FormItem>
              <FormItem
                name='captcha_response'
                rules={[ { required: true, message: 'Captcha cannot be empty' }]}
              >
              <div 
                className="g-recaptcha"
                id="recaptcha"
                ref={this.handleRecaptchaRef} 
                data-sitekey={REACT_APP_CAPTCHA_SITE_KEY}
              />
              </FormItem>
              <Row justify='space-between' align='middle'>
                <Col span={12}>
                  <div className='faded-text' onClick={this.handleForgotPassword}>Forgot Password</div>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit" className='sign-button' loading={!!loginLoading}>
                    SIGN IN
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </SignInWrapper>
    )
  }
}

export default connect(
  state => ({
    loginLoading: state.Loading.LOGIN,
    loginError: state.Error.LOGIN,
    loginMessage: state.Auth.loginMessage,
    isLoggedIn: !!state.Auth.idToken,
    loginData: state.Auth.loginData
  }),
  {
    resetLoadingRedux,
    resetErrorRedux,
    login
  }
)(SignIn)
