import { get } from 'lodash'
import actions from './actions'

const initState = {
  claimList: {},
  claimListMessage: "",
  signedUrl: [],
  signedUrlStatus: "",
  failedFileData: {},
  serviceCenterList: []
}

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_CLAIM_LIST_SUCCESS:
    case actions.GET_CLAIM_LIST_FAILURE:
      return {
        ...state,
        claimList: action.data
      }
    case actions.GET_CLAIM_DETAIL_SUCCESS:
    case actions.GET_CLAIM_DETAIL_FAILURE:
      return {
        ...state,
        claimDetails: action.data,
        claimDetailsMessage: action.message
      }
    case actions.CLAIM_EMAIL_LIST_SUCCESS:
    case actions.CLAIM_EMAIL_LIST_FAILURE:
      return {
        ...state,
        emailClaimList: action.data,
        emailClaimListMessage: action.message
      }
    case actions.GET_CLAIM_DASHBOARD_DATA_SUCCESS:
    case actions.GET_CLAIM_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        claimDashboardData: action.data
      }
    case actions.TRIGGER_CLAIM_EVENT_SUCCESS:
    case actions.TRIGGER_CLAIM_EVENT_FAILURE:
      return {
        ...state,
        triggerClaimEventMessage: action.message
      }
    case actions.TRIGGER_CLAIM_SALVAGE_EVENT_SUCCESS:
    case actions.TRIGGER_CLAIM_SALVAGE_EVENT_FAILURE:
      return {
        ...state,
        triggerClaimSalvageEventMessage: action.message
      }
    case actions.TRIGGER_CLAIM_ATTRIBUTE_EVENT_SUCCESS:
    case actions.TRIGGER_CLAIM_ATTRIBUTE_EVENT_FAILURE:
      return {
        ...state,
        triggerClaimAttributeEventMessage: action.message
      }
    case actions.GET_CLAIM_FILTERS_SUCCESS:
    case actions.GET_CLAIM_FILTERS_FAILURE:
      return {
        ...state,
        claimFilters: action.data
      }
    case actions.SAVE_FORM_SUCCESS:
    case actions.SAVE_FORM_FAILURE:
      return {
        ...state,
        saveFormData: action.data,
        saveFormDataMessage: action.message
      }
    case actions.GET_CHOICES_DATA_SUCCESS:
    case actions.GET_CHOICES_DATA_FAILURE:
      return {
        ...state,
        choicesData: action.data
      }
    case actions.GET_FEEDBACK_DASHBOARD_DATA_SUCCESS:
    case actions.GET_FEEDBACK_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        feedbackDashboardData: action.data
      }
    case actions.GET_FEEDBACK_LIST_DATA_SUCCESS:
    case actions.GET_FEEDBACK_LIST_DATA_FAILURE:
      return {
        ...state,
        feedbackList: action.data
      }
    case actions.GENERATE_SIGNED_URL_SUCCESS:
    case actions.GENERATE_SIGNED_URL_FAILURE:
      return {
        ...state,
        signedUrlStatus: action.message,
        signedUrl: action.data
      }
    case actions.CLAIMS_FILE_UPLOAD_FAILURE:
    case actions.CLAIMS_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        failedFileData: action.fileData
      }
    case actions.RESET_UPLOAD_DATA:
      return{
        ...state,
        signedUrlStatus: "",
        signedUrl: [],
        failedFileData: {},
      }
    case actions.GET_SERVICE_CENTER_LIST_SUCCESS:
    case actions.GET_SERVICE_CENTER_LIST_FAILURE:
      return{
        ...state,
        serviceCenterList: get(action, 'data', [])
      }
    default:
      return state
  }
}
