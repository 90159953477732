import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import PropTypes from 'prop-types'

import appActions from '../../redux/app/actions'
import { TopbarWrapper } from './topbar.style'
import WebTopbar from './webTopBar'

const { toggleCollapsed } = appActions

class Topbar extends Component {
  state = {
    width: '',
    active: 0
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const { history, userPermissions, providerDetails } = this.props

    return (
      <TopbarWrapper>
        <Layout>
          <WebTopbar
            history={history}
            userPermissions={userPermissions}
            providerDetails={providerDetails}
          />
        </Layout>
      </TopbarWrapper>
    )
  }
}

Topbar.propTypes = {
  config: PropTypes.array.isRequired,
  userDetails: PropTypes.object.isRequired,
  permissions: PropTypes.array.isRequired
}

Topbar.defaultProps = {
  config: [],
  userDetails: {},
  permissions: []
}

export default connect(state => ({
  App: state.App,
  userPermissions: state.App.userPermissions,
  providerDetails: state.App.providerDetails
}), { toggleCollapsed })(Topbar)
