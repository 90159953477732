const BASE_URL = process.env.REACT_APP_API_ENDPOINT
const BASE_PATH = '/api/v1/user/'
const BASE_AUTH_PATH = '/api/v1/auth/'

export default {
  loginUser: `${BASE_URL}${BASE_PATH}login-user/`,
  logoutUser: `${BASE_URL}${BASE_AUTH_PATH}logout-user/`,
  verifyOtp: `${BASE_URL}${BASE_PATH}verify-otp/`,
  resendOtp: `${BASE_URL}${BASE_PATH}resend-otp/`,
}
