export const QueryToObject = (query) => {
  const urlParams = new URLSearchParams(query)
  const entries = urlParams.entries()
  const params = paramsToObject(entries)
  return params
}

function paramsToObject(entries) {
  let result = {}
  for (let entry of entries) {
    const [key, value] = entry
    if (result[key]) {
      result[key] = [result[key], value]
    } else {
      result[key] = value
    }
  }
  return result
}

export function queryStringParser(queryString) {
  const regex = /[?&]([^=#]+)=([^&#]*)/g
    let url = queryString
    let params = {}
    let match = regex.exec(url)
    while (match) {
        params[match[1]] = match[2];
        match = regex.exec(url)
    }
  return params
} 
