import actions from './actions'

const initState = {
  notifDetails: {}
}

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.NOTIFICATIONS_LIST_SUCCESS:
    case actions.NOTIFICATIONS_LIST_FAILURE:
      return {
        ...state,
        notifDetails: action.data
      }
    default:
      return state
  }
}
