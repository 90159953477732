import actions from './action'

const initState = {
  guidelineList: []
}

export default function guidelineReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_GUIDELINE_LIST_SUCCESS:
    case actions.GET_GUIDELINE_LIST_FAILURE:
      
      return {
        ...state,
        guidelineList: action.data
      }
    default:
      return state
  }
}
