import actions from './action'

const initState = {
  policyList: {},
  policyListCount: {},
  policyDetails: {},
  changeHistoryData: [],
  packagesList: [],
  paymentList: {},
}

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_POLICY_LIST_SUCCESS:
    case actions.GET_POLICY_LIST_FAILURE:
      return {
        ...state,
        policyList: action.data
      }
    case actions.GET_POLICY_LIST_COUNT_SUCCESS:
    case actions.GET_POLICY_LIST_COUNT_FAILURE:
      return {
        ...state,
        policyListCount: action.data
      }
    case actions.GET_POLICY_DETAILS_SUCCESS:
    case actions.GET_POLICY_DETAILS_FAILURE:
      return {
        ...state,
        policyDetails: action.data,
        policyDetailsMessage: action.message
      }
    case actions.GET_DASHBOARD_DATA_SUCCESS:
    case actions.GET_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        policyDashboardData: action.data
      }
    case actions.GET_PARTNERS_LIST_SUCCESS:
    case actions.GET_PARTNERS_LIST_FAILURE:
      return {
        ...state,
        partnersList: action.data
      }
    case actions.GET_PRODUCTS_LIST_SUCCESS:
    case actions.GET_PRODUCTS_LIST_FAILURE:
      return {
        ...state,
        productsList: action.data
      }
    case actions.GET_PACKAGES_LIST_SUCCESS:
    case actions.GET_PACKAGES_LIST_FAILURE:
      return {
        ...state,
        packagesList: action.data
      }
    case actions.EMAIL_LIST_SUCCESS:
    case actions.EMAIL_LIST_FAILURE:
      return {
        ...state,
        emailListMessage: action.message
      }
    case actions.EMAIL_LIST_SUCCESS_RANGE:
    case actions.EMAIL_LIST_FAILURE_RANGE:
      return {
          ...state,
          emailRangeListMessage: action.message
        }
    case actions.GET_CHANGE_HISTORY_DATA_SUCCESS:
    case actions.GET_CHANGE_HISTORY_DATA_FAILURE:
      return {
        ...state,
        changeHistoryData: action.data
      }
    case actions.GET_PAYMENT_LIST_SUCCESS:
    case actions.GET_PAYMENT_LIST_FAILURE:
      return {
        ...state,
        paymentList: action.data
      }
    default:
      return state
  }
}
