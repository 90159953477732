const actions = {
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  SEND_SMS_OTP_REQUEST: 'SEND_SMS_OTP_REQUEST',
  SEND_SMS_OTP_SUCCESS: 'SEND_SMS_OTP_SUCCESS',
  SEND_SMS_OTP_FAILURE: 'SEND_SMS_OTP_FAILURE',

  VERIFY_AND_ADD_PHONE_REQUEST: 'VERIFY_AND_ADD_PHONE_REQUEST',
  VERIFY_AND_ADD_PHONE_SUCCESS: 'VERIFY_AND_ADD_PHONE_SUCCESS',
  VERIFY_AND_ADD_PHONE_FAILURE: 'VERIFY_AND_ADD_PHONE_FAILURE',

  RESEND_USER_OTP_REQUEST: 'RESEND_USER_OTP_REQUEST',
  RESEND_USER_OTP_SUCCESS: 'RESEND_USER_OTP_SUCCESS',
  RESEND_USER_OTP_FAILURE: 'RESEND_USER_OTP_FAILURE',

  GET_TOTP_SHARED_SECRET_REQUEST: 'GET_TOTP_SHARED_SECRET_REQUEST',
  GET_TOTP_SHARED_SECRET_SUCCESS: 'GET_TOTP_SHARED_SECRET_SUCCESS',
  GET_TOTP_SHARED_SECRET_FAILURE: 'GET_TOTP_SHARED_SECRET_FAILURE',

  VERIFY_TOTP_REQUEST: 'VERIFY_TOTP_REQUEST',
  VERIFY_TOTP_SUCCESS: 'VERIFY_TOTP_SUCCESS',
  VERIFY_TOTP_FAILURE: 'VERIFY_TOTP_FAILURE',

  UPDATE_2FA_METHOD_REQUEST: 'UPDATE_2FA_METHOD_REQUEST',
  UPDATE_2FA_METHOD_SUCCESS: 'UPDATE_2FA_METHOD_SUCCESS',
  UPDATE_2FA_METHOD_FAILURE: 'UPDATE_2FA_METHOD_FAILURE',

  VERIFY_USER_OTP_REQUEST: 'VERIFY_USER_OTP_REQUEST',
  VERIFY_USER_OTP_SUCCESS: 'VERIFY_USER_OTP_SUCCESS',
  VERIFY_USER_OTP_FAILURE: 'VERIFY_USER_OTP_FAILURE',

  DISABLE_2FA_REQUEST: 'DISABLE_2FA_REQUEST',
  DISABLE_2FA_SUCCESS: 'DISABLE_2FA_SUCCESS',
  DISABLE_2FA_FAILURE: 'DISABLE_2FA_FAILURE',

  UPDATE_TOPBAR_ACTIVE_MODAL: 'UPDATE_TOPBAR_ACTIVE_MODAL',

  forgotPassword: payload => ({
    type: actions.FORGOT_PASSWORD_REQUEST,
    payload
  }),
  resetPassword: (payload) => ({
    type: actions.RESET_PASSWORD_REQUEST,
    payload
  }),
  updateTopbarActiveModal: payload => ({
    type: actions.UPDATE_TOPBAR_ACTIVE_MODAL,
    data: payload
  }),
  sendOTPToUser: payload => ({
    type: actions.SEND_SMS_OTP_REQUEST,
    payload
  }),
  verifyOTPAndAddPhone: payload => ({
    type: actions.VERIFY_AND_ADD_PHONE_REQUEST,
    payload
  }),
  resendUserOTP: payload => ({
    type: actions.RESEND_USER_OTP_REQUEST,
    payload
  }),
  getTOTPSharedSecret: () => ({
    type: actions.GET_TOTP_SHARED_SECRET_REQUEST
  }),
  verifyTOTP: payload => ({
    type: actions.VERIFY_TOTP_REQUEST,
    payload
  }),
  update2FAMethod: payload => ({
    type: actions.UPDATE_2FA_METHOD_REQUEST,
    payload
  }),
  verifyUserOTP: payload => ({
    type: actions.VERIFY_USER_OTP_REQUEST,
    payload
  }),
  disable2FA: payload => ({
    type: actions.DISABLE_2FA_REQUEST,
    payload
  })
}
export default actions
