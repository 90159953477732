import Auth from './auth/reducer'
import App from './app/reducer'
import Error from './error/reducer'
import Loading from './loading/reducer'
import User from '../containers/redux/user/reducer'
import Claim from '../containers/redux/claims/reducer'
import Policy from '../containers/redux/policy/reducer'
import Notification from '../containers/redux/notifications/reducer'
import UserManagement from '../containers/redux/userManagement/reducer'
import Guideline from '../containers/redux/guideline/reducer'


export default {
  Auth,
  App,
  Error,
  Loading,
  User,
  Claim,
  Policy,
  Notification,
  UserManagement,
  Guideline
}
