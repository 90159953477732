import styled from 'styled-components'
import { APP_COLORS } from '../../../../constants/colors'
import { GENERIC_IMAGES } from '../../../../constants/images'

export const ResetPasswordWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background: url(${GENERIC_IMAGES.SIGNIN_BG}) no-repeat center center;
  background-size: cover;
  .inner-container {
    background-color: rgba(0,0,0,0.6);
    height: inherit;
    width: inherit;
  }
  .right-container {
    background-color: #fff;
    position: absolute;
    height: -webkit-fill-available;
    width: 500px;
    right: 0;
    padding: 70px 50px;
    min-height: 100vh;
  }
  .header {
    color: #788195;
    font-size: 24px;
    text-align: center;
    margin-bottom: 36px;
  }
  .password-heading {
    font-weight: 600;
    margin-top: 24px;
  }
  .ant-input {
    border: 1px solid #e9e9e9;
    font-size: 14px;
  }
  .sign-button {
    width: 160px;
    height: 38px;
    border-radius: 4px;
  }
  .forgot-heading {
    font-size: 14px;
  }
  .forgot-subheading {
    color: #979797;
    font-size: 13px;
    margin-bottom: 14px;
  }
  .faded-text {
    color: #797979;
    font-size: 14px;
    cursor: pointer;
  }
  .ant-form-item-explain {
    margin-bottom: 16px;
  }
  .ant-btn-primary {
    background-color: ${APP_COLORS.primary};
    border-color: ${APP_COLORS.primary};
  }
`
