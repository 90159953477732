import React from "react";
import { Provider } from "react-redux"
import { store, history } from "./redux/store"
import PublicRoutes from "./router";
import DashAppHolder from "./dashAppStyle";
import Boot from "./redux/boot";
import * as Sentry from '@sentry/react'

const App = () => (
  <Sentry.ErrorBoundary fallback={'An error has occurred'}>
    <DashAppHolder>
      <Provider store={store}>
        <PublicRoutes history={history} />
      </Provider>
    </DashAppHolder>
  </Sentry.ErrorBoundary>
)
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App
