import actions from './action'

const initState = {
  forgotPasswardMessage: '',
  resetPasswordMessage: ''
}

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.FORGOT_PASSWORD_SUCCESS:
    case actions.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswardMessage: action.message
      }
    case actions.RESET_PASSWORD_SUCCESS:
    case actions.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordMessage: action.message
      }
    case actions.UPDATE_TOPBAR_ACTIVE_MODAL:
      return {
        ...state,
        topbarActiveModal: action.data
      }
    case actions.SEND_SMS_OTP_SUCCESS:
    case actions.SEND_SMS_OTP_FAILURE:
      return {
        ...state,
        sendSMSOtpData: action.data,
        sendSMSOtpMessage: action.message
      }
    case actions.VERIFY_AND_ADD_PHONE_SUCCESS:
    case actions.VERIFY_AND_ADD_PHONE_FAILURE:
      return {
        ...state,
        verifyAndAddNumberMessage: action.message
      }
    case actions.RESEND_USER_OTP_SUCCESS:
    case actions.RESEND_USER_OTP_FAILURE:
      return {
        ...state,
        resendUserOTPMessage: action.message
      }
    case actions.GET_TOTP_SHARED_SECRET_SUCCESS:
    case actions.GET_TOTP_SHARED_SECRET_FAILURE:
      return {
        ...state,
        totpSharedSecret: action.data,
        totpSharedSecretMessage: action.message
      }
    case actions.VERIFY_TOTP_SUCCESS:
    case actions.VERIFY_TOTP_FAILURE:
      return {
        ...state,
        verifyTOTPMessage: action.message
      }
    case actions.UPDATE_2FA_METHOD_SUCCESS:
    case actions.UPDATE_2FA_METHOD_FAILURE:
      return {
        ...state,
        update2FAMethodMessage: action.message
      }
    case actions.VERIFY_USER_OTP_SUCCESS:
    case actions.VERIFY_USER_OTP_FAILURE:
      return {
        ...state,
        verifyUserOTPMessage: action.message
      }
    case actions.DISABLE_2FA_SUCCESS:
    case actions.DISABLE_2FA_FAILURE:
      return {
        ...state,
        disable2FAMessage: action.message
      }
    default:
      return state
  }
}
