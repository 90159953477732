import styled from "styled-components"
import WithDirection from "../../settings/withDirection"

const TopbarDropdownWrapper = styled.div`
  .user-dropdown {
    cursor: pointer;
    color: #FF0000;
  }
  .username-dropdown {
    /* margin-bottom: 4px; */
    /* border-bottom: 1px solid #e9e9e9; */
    /* padding-bottom: 8px; */
  }
  .user-name {
    font-weight: 600;
  }
  .link {
    cursor: pointer;
  }
  .logout {
    color: #FF0000;
  }
  .ant-divider-horizontal {
    margin: 12px 0px !important;
  }
`;

export const UserProfile = styled.div`
 `
export default WithDirection(TopbarDropdownWrapper)
