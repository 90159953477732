const actions = {
    GET_GUIDELINE_LIST_REQUEST: 'GET_GUIDELINE_LIST_REQUEST',
    GET_GUIDELINE_LIST_SUCCESS: 'GET_GUIDELINE_LIST_SUCCESS',
    GET_GUIDELINE_LIST_FAILURE: 'GET_GUIDELINE_LIST_FAILURE',
  
    getGuidelineList: () => ({
      type: actions.GET_GUIDELINE_LIST_REQUEST
    })
  }
  export default actions
  