import React, { Component } from "react";
import { Menu, Drawer } from "antd";

import PermissionChecker from "../../components/PermissionChecker";
import ConfigChecker from "../../components/ConfigChecker";
import TopbarUser from "./topbarUser";
import { WebTopBarStyleWrapper } from "./topbar.style";
import { GENERIC_IMAGES } from "../../constants/images";
import { get } from "lodash";

const { SubMenu, Item: MenuItem } = Menu;

const logo = (()=>{
  if(window.location.hostname.includes('ppibroker')) return GENERIC_IMAGES.PPBROKER_LOGO
  return GENERIC_IMAGES.PASARPOLIS_LOGO
})()

class WebTopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      windowWidth: window.innerWidth,
    };
  }
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  handleMenuClick = (path) => {
    this.props.history.push({ pathname: path, search: "" });
  };

  checkRequiredPermission = (permissionName) => {
    const { userPermissions } = this.props;
    return !!userPermissions.find(
      ({ codename }) => codename === permissionName
    );
  };

  checkSubMenuConfig = (requiredConfig, requiredPermission) => {
    const { providerDetails } = this.props;
    const partnerConfigs = get(providerDetails, "config", []);
    const checkConfig = !!partnerConfigs.find(
      ({ config_type = {}, value }) =>
        value === "1" && config_type.name === requiredConfig
    );

    return this.checkRequiredPermission(requiredPermission) && checkConfig;
  };
  showDrawer = () => {
    this.setState({ visible: !this.state.visible });
  };
  onClose = () => {
    this.setState({ visible: !this.state.visible });
  };
  renderMenuItem = (title, path, requiredConfig, requiredPermission) => {
    return (
      <MenuItem
        {...this.props}
        key={path}
        onClick={() => this.handleMenuClick(path)}
      >
        {requiredPermission && requiredConfig ? (
          <PermissionChecker requiredPermission={requiredPermission}>
            <ConfigChecker requiredConfig={requiredConfig} requiredValue="1">
              {title}
            </ConfigChecker>
          </PermissionChecker>
        ) : (
          title
        )}
      </MenuItem>
    );
  };

  renderSubMenuItems = (MenuHeading, subMenus = []) => {
    const subMenuList = subMenus.filter((subMenuItem) => {
      return this.checkSubMenuConfig(subMenuItem[2], subMenuItem[3]);
    });

    if (!subMenuList.length) {
      return null;
    }
    return (
      <SubMenu key="SubMenu" title={MenuHeading}>
        {subMenuList.map((subMenuItem) => {
          return this.renderMenuItem(subMenuItem[0], subMenuItem[1]);
        })}
      </SubMenu>
    );
  };
  renderMainMenu = (mode, defaultkeys, selectedkeys) => {
    return (
      <Menu
        mode={mode}
        defaultSelectedKeys={defaultkeys}
        selectedKeys={selectedkeys}
      >
        {this.renderMenuItem(
          "Dashboard",
          "/dashboard",
          "DISPLAY_POLICY_DASHBOARD",
          "view_dashboard"
        )}
        {this.renderMenuItem(
          "Policy",
          "/policy",
          "DISPLAY_POLICY_LIST",
          "view_policy_list"
        )}
        {this.renderSubMenuItems("Claim", [
          ["Claim List", "/claim", "DISPLAY_CLAIM_LIST", "view_claim_list"],
          [
            "Customer Feedback",
            "/customer-feedback",
            "DISPLAY_CUSTOMER_FEEDBACK",
            "view_customer_feedback",
          ],
        ])}
        {this.renderMenuItem(
          "User Management",
          "/user-management",
          "DISPLAY_USER_MANAGEMENT",
          "view_user_management"
        )}
        {this.renderMenuItem("Guideline", "/guideline")}
      </Menu>
    );
  };

  render() {
    const { history } = this.props;
    let { pathname = "/" } = history.location;
    let splittedPathname = pathname.split("/");

    return (
      <WebTopBarStyleWrapper>
        <div className="topbar-container">
          <div className="logo-image">
            <a href="/dashboard">
              <img
                width="100%"
                height="auto"
                className="logo"
                src={logo}
                alt="Pasarpolis Logo"
              />
            </a>
          </div>
          {this.state.windowWidth > 800
            ? this.renderMainMenu(
                "horizontal",
                [`/${splittedPathname[1]}`],
                [`/${splittedPathname[1]}`]
              )
            : ""}

          <Drawer
            title={
              <div
                className="side-menu-profile"
                style={{ width: "fit-content", margin: "5%" }}
              >
                <TopbarUser />
              </div>
            }
            placement="right"
            onClose={this.onClose}
            visible={this.state.visible}
          >
            {this.renderMainMenu(
              "vertical",
              [`/${splittedPathname[1]}`],
              [`/${splittedPathname[1]}`]
            )}
          </Drawer>
        </div>

        {this.state.windowWidth > 800 ? (
          <TopbarUser />
        ) : (
          <div
            style={{
              fontSize: 30,
              cursor: "pointer",
            }}
            onClick={this.showDrawer}
          >
            <div
              style={{
                marginTop: "40%",
              }}
            >
              <span
                style={{
                  display: "block",
                  height: 4,
                  width: 40,
                  borderRadius: 3.5,
                  backgroundColor: "#5c5c5c",
                  marginLeft: 20,
                  marginTop: 3,
                }}
              />
              <span
                style={{
                  display: "block",
                  height: 4,
                  width: 31,
                  borderRadius: 3.5,
                  backgroundColor: "#5c5c5c",
                  marginLeft: 29,
                  marginTop: 5,
                }}
              />
              <span
                style={{
                  display: "block",
                  height: 4,
                  width: 22,
                  borderRadius: 3.5,
                  backgroundColor: "#5c5c5c",
                  marginLeft: 37,
                  marginTop: 5,
                }}
              />
            </div>
          </div>
        )}
      </WebTopBarStyleWrapper>
    );
  }
}

export default WebTopBar;
