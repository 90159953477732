import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import 'antd/dist/antd.css'
import App from './App'
import * as Sentry from '@sentry/react'

if (process.env.REACT_APP_DSN) {
  Sentry.init({
    dsn: `${process.env.REACT_APP_DSN}`,
    // autoSessionTracking: true,
    // integrations: [
    //   new Integrations.BrowserTracing()
    // ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1
  })
}

ReactDOM.render(<App />,
  document.getElementById('root')
)

