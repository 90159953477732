import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import { get } from 'lodash'
import actions from './action'
import authPost from '../../../request/methods/authPost'
import authGet from '../../../request/methods/authGet'
import userManagementUrls from '../../../request/endpoint/userManagement'
import { parseResponseError } from '../../../helpers/parseResponseError'

export function* getUserList() {
  yield takeEvery(actions.GET_USER_LIST_REQUEST, function* () {
    try {
      let res = yield call(authGet, { url: userManagementUrls.getUserList })
      if (res.status === 200) {
        yield put({
          type: actions.GET_USER_LIST_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_USER_LIST_FAILURE,
          data: [],
          message: get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_USER_LIST_FAILURE,
        data: [],
        message: get(e.data, 'message', 'Error')
      })
    }
  })
}

export function* getGroupList() {
  yield takeEvery(actions.GET_GROUP_LIST_REQUEST, function* () {
    try {
      let res = yield call(authGet, { url: userManagementUrls.getGroupList })
      if (res.status === 200) {
        yield put({
          type: actions.GET_GROUP_LIST_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_GROUP_LIST_FAILURE,
          data: [],
          message: get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_GROUP_LIST_FAILURE,
        data: [],
        message: get(e.data, 'message', 'Error')
      })
    }
  })
}

export function* addUser() {
  yield takeEvery(actions.ADD_USER_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: userManagementUrls.addUser })
      if (res.status === 200) {
        yield put({
          type: actions.ADD_USER_SUCCESS,
          message: get(res.data, 'message', 'Success')
        })
        yield put({
          type: actions.GET_USER_LIST_REQUEST
        })
      } else {
        yield put({
          type: actions.ADD_USER_FAILURE,
          message: parseResponseError(get(res.data, 'error'), 'Something went wrong.')
        })
      }
    } catch (e) {
      yield put({
        type: actions.ADD_USER_FAILURE,
        message: parseResponseError(get(e.data, 'error'), 'Something went wrong.')
      })
    }
  })
}

export function* editUser() {
  yield takeEvery(actions.EDIT_USER_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: userManagementUrls.editUser })
      if (res.status === 202) {
        yield put({
          type: actions.EDIT_USER_SUCCESS,
          message: get(res.data, 'message', 'Success')
        })
        yield put({
          type: actions.GET_USER_LIST_REQUEST
        })
      } else {
        yield put({
          type: actions.EDIT_USER_FAILURE,
          message: parseResponseError(get(res.data, 'error'), 'Something went wrong.')
        })
      }
    } catch (e) {
      yield put({
        type: actions.EDIT_USER_FAILURE,
        message: parseResponseError(get(e.data, 'error'), 'Something went wrong.')
      })
    }
  })
}

export function* updateUserStatus() {
  yield takeEvery(actions.UPDATE_USER_STATUS_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: userManagementUrls.updateStatus })
      if (res.status === 200) {
        yield put({
          type: actions.UPDATE_USER_STATUS_SUCCESS,
          message: get(res.data, 'message', 'Success')
        })
        yield put({
          type: actions.GET_USER_LIST_REQUEST
        })
      } else {
        yield put({
          type: actions.UPDATE_USER_STATUS_FAILURE,
          message: get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.UPDATE_USER_STATUS_FAILURE,
        message: get(e.data, 'error', 'Error')
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getUserList),
    fork(getGroupList),
    fork(addUser),
    fork(editUser),
    fork(updateUserStatus)
  ])
}
