import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import { get } from 'lodash'
import actions from './actions'
import authGet from '../../../request/methods/authGet'
import authPost from '../../../request/methods/authPost'
import authPut from '../../../request/methods/authPut'
import authPostForm from '../../../request/methods/authPostForm'
import claimUrls from '../../../request/endpoint/claim'

export function* getClaimList() {
  yield takeEvery(actions.GET_CLAIM_LIST_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: claimUrls.getClaimList })
      if (res.status === 200) {
        yield put({
          type: actions.GET_CLAIM_LIST_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_CLAIM_LIST_FAILURE,
          data: {},
          message: get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_CLAIM_LIST_FAILURE,
        data: {},
        message: get(e.data, 'message', 'Error')
      })
    }
  })
}

export function* getClaimDetails() {
  yield takeEvery(actions.GET_CLAIM_DETAIL_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: claimUrls.claimDetailUrl })
      if (res.status === 200) {
        yield put({
          type: actions.GET_CLAIM_DETAIL_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_CLAIM_DETAIL_FAILURE,
          data: {},
          message: get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_CLAIM_DETAIL_FAILURE,
        data: {},
        message: get(e.data, 'error', 'Error')
      })
    }
  })
}

export function* getClaimDashboardData() {
  yield takeEvery(actions.GET_CLAIM_DASHBOARD_DATA_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: claimUrls.getClaimDashboardData })
      if (res.status === 200) {
        yield put({
          type: actions.GET_CLAIM_DASHBOARD_DATA_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_CLAIM_DASHBOARD_DATA_FAILURE,
          data: {},
          message: get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_CLAIM_DASHBOARD_DATA_FAILURE,
        data: {},
        message: get(e.data, 'message', 'Error')
      })
    }
  })
}

export function* emailClaimList() {
  yield takeEvery(actions.CLAIM_EMAIL_LIST_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: claimUrls.emailList })
      if (res.status === 200) {
        yield put({
          type: actions.CLAIM_EMAIL_LIST_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.CLAIM_EMAIL_LIST_FAILURE,
          data: {},
          message: get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.CLAIM_EMAIL_LIST_FAILURE,
        data: {},
        message: get(e.data, 'error', 'Error')
      })
    }
  })
}

export function* triggerClaimEvent() {
  yield takeEvery(actions.TRIGGER_CLAIM_EVENT_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: claimUrls.triggerClaimEvent })
      if (res.status === 200) {
        const { body: payloadBody } = payload
        const dataPayload = { query: { claim_id: get(payloadBody, 'claim_id') } }
        yield put({
          type: actions.TRIGGER_CLAIM_EVENT_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success')
        })
        yield put({
          type: actions.GET_CLAIM_DETAIL_REQUEST,
          payload: dataPayload
        })
      } else {
        yield put({
          type: actions.TRIGGER_CLAIM_EVENT_FAILURE,
          data: {},
          message: get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.TRIGGER_CLAIM_EVENT_FAILURE,
        data: {},
        message: get(e.data, 'error', 'Error')
      })
    }
  })
}

export function* triggerClaimSalvageEvent() {
  yield takeEvery(actions.TRIGGER_CLAIM_SALVAGE_EVENT_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: claimUrls.triggerClaimSalvageEvent })
      if (res.status === 200) {
        const { body: payloadBody } = payload
        const dataPayload = { query: { claim_id: get(payloadBody, 'claim_id') } }
        yield put({
          type: actions.TRIGGER_CLAIM_SALVAGE_EVENT_SUCCESS,
          data: res.data,
          message: get(res.data, 'status', 'Success')
        })
        yield put({
          type: actions.GET_CLAIM_DETAIL_REQUEST,
          payload: dataPayload
        })
      } else {
        yield put({
          type: actions.TRIGGER_CLAIM_SALVAGE_EVENT_FAILURE,
          data: {},
          message: get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.TRIGGER_CLAIM_SALVAGE_EVENT_FAILURE,
        data: {},
        message: get(e.data, 'error', 'Error')
      })
    }
  })
}

export function* triggerClaimAttributeEvent() {
  yield takeEvery(actions.TRIGGER_CLAIM_ATTRIBUTE_EVENT_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: claimUrls.triggerClaimEvent })
      if (res.status === 200) {
        const { body: payloadBody } = payload
        const dataPayload = { query: { claim_id: get(payloadBody, 'claim_id') } }
        yield put({
          type: actions.TRIGGER_CLAIM_ATTRIBUTE_EVENT_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success')
        })
        yield put({
          type: actions.GET_CLAIM_DETAIL_REQUEST,
          payload: dataPayload
        })
      } else {
        yield put({
          type: actions.TRIGGER_CLAIM_ATTRIBUTE_EVENT_FAILURE,
          data: {},
          message: get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.TRIGGER_CLAIM_ATTRIBUTE_EVENT_FAILURE,
        data: {},
        message: get(e.data, 'error', 'Error')
      })
    }
  })
}

export function* getClaimFilters() {
  yield takeEvery(actions.GET_CLAIM_FILTERS_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: claimUrls.getClaimFilters })
      if (res.status === 200) {
        yield put({
          type: actions.GET_CLAIM_FILTERS_SUCCESS,
          data: res.data,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_CLAIM_FILTERS_FAILURE,
          data: {},
          message: get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_CLAIM_FILTERS_FAILURE,
        data: {},
        message: get(e.data, 'message', 'Error')
      })
    }
  })
}

export function* saveForm() {
  yield takeEvery(actions.SAVE_FORM_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: claimUrls.saveForm })
      if (res.status === 200) {
        const { body: payloadBody } = payload
        const dataPayload = { query: { claim_id: get(payloadBody, 'claim_id') } }
        yield put({
          type: actions.SAVE_FORM_SUCCESS,
          data: res.data,
          message: get(res.data, 'message.status', 'Form Saved')
        })
        yield put({
          type: actions.GET_CLAIM_DETAIL_REQUEST,
          payload: dataPayload
        })
      } else {
        yield put({
          type: actions.SAVE_FORM_FAILURE,
          data: {},
          message: get(res.data, 'message.message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.SAVE_FORM_FAILURE,
        data: {},
        message: get(e.data, 'message.message', 'Error')
      })
    }
  })
}

export function* getChoicesData() {
  yield takeEvery(actions.GET_CHOICES_DATA_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: claimUrls.getChoicesData })
      if (res.status === 200) {
        yield put({
          type: actions.GET_CHOICES_DATA_SUCCESS,
          data: res.data,
          message: get(res.data, 'message.status', 'Form Saved')
        })
      } else {
        yield put({
          type: actions.GET_CHOICES_DATA_FAILURE,
          data: [],
          message: get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_CHOICES_DATA_FAILURE,
        data: [],
        message: get(e.data, 'message', 'Error')
      })
    }
  })
}

export function* getFeedbackDashboardData() {
  yield takeEvery(actions.GET_FEEDBACK_DASHBOARD_DATA_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: claimUrls.getFeedbackDashboard })
      if (res.status === 200) {
        yield put({
          type: actions.GET_FEEDBACK_DASHBOARD_DATA_SUCCESS,
          data: res.data,
          message: get(res.data, 'message.status', 'Form Saved')
        })
      } else {
        yield put({
          type: actions.GET_FEEDBACK_DASHBOARD_DATA_FAILURE,
          data: {},
          message: get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_FEEDBACK_DASHBOARD_DATA_FAILURE,
        data: {},
        message: get(e.data, 'message', 'Error')
      })
    }
  })
}

export function* getFeedbackListData() {
  yield takeEvery(actions.GET_FEEDBACK_LIST_DATA_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: claimUrls.getFeedbackList })
      if (res.status === 200) {
        yield put({
          type: actions.GET_FEEDBACK_LIST_DATA_SUCCESS,
          data: res.data['claim_feedback_list'],
          message: get(res.data, 'message', 'Form Saved')
        })
      } else {
        yield put({
          type: actions.GET_FEEDBACK_LIST_DATA_FAILURE,
          data: [],
          message: get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_FEEDBACK_LIST_DATA_FAILURE,
        data: [],
        message: get(e.data, 'message', 'Error')
      })
    }
  })
}

export function * generateSignedUrl () {
  yield takeEvery(actions.GENERATE_SIGNED_URL_REQUEST, function * ({ payload }) {
    try {
      const res =  yield call(authPost, { body: payload.body, url: claimUrls.generateSignedUrl })
      if (res.status === 200) {
        yield put({
          type: actions.GENERATE_SIGNED_URL_SUCCESS,
          data: res.data.data,
          message: 'Success'
        })
        yield put({
          type: actions.CLAIMS_FILE_UPLOAD_REQUEST,
          payload: {
            url: res.data.data.url,
            file: payload.uploadedFile
          },
        })
      } else {
        yield put({
          type: actions.GENERATE_SIGNED_URL_FAILURE,
          data: '',
          message: get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GENERATE_SIGNED_URL_FAILURE,
        data: '',
        message: get(e.data, 'message', 'Error')
      })
    }
  })
}

export function * claimsFileUpload () {
  yield takeEvery(actions.CLAIMS_FILE_UPLOAD_REQUEST, function * ({ payload }) {
    try {
      const headers = { 'Content-Type': payload.file.type, 'Content-Length': payload.file.size, Host: 'storage.googleapis.com' }
      const res =  yield call(authPut, { body: payload.file, url: payload.url, headers })
      if (res.status === 200) {
        yield put({
          type: actions.CLAIMS_FILE_UPLOAD_SUCCESS,
          fileData: {},
          message: 'Success'
        })
      } else {
        yield put({
          type: actions.CLAIMS_FILE_UPLOAD_FAILURE,
          fileData: payload,
          message: get(res.data, 'message', 'Failed')
        })
      }
    } catch (e) {
      yield put({
        type: actions.CLAIMS_FILE_UPLOAD_FAILURE,
        fileData: payload,
        message: get(e.data, 'message', 'Failed')
      })
    }
  })
}

export function * getServiceCenterList(){
  yield takeEvery(actions.GET_SERVICE_CENTER_LIST_REQUEST, function*({payload}){
    try{
      const res =  yield call(authGet, { query: payload, url:  claimUrls.getServiceCenterList })
      if([200,201].includes(res.status)){
        yield put({
         type: actions.GET_SERVICE_CENTER_LIST_SUCCESS,
         data: get(res, 'data.data', []),
         message: get(res, 'data.message', []),
        })
      }else{
        yield put({
          type: actions.GET_SERVICE_CENTER_LIST_FAILURE,
          data: get(res, 'data.data', []),
          message: get(res, 'data.message', []),
        })
      }
    } catch(e){
      yield put({
        type: actions.GET_SERVICE_CENTER_LIST_FAILURE,
        data: get(e, 'data.data', []),
        message: get(e, 'data.message', []),
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getClaimList),
    fork(emailClaimList),
    fork(getClaimDetails),
    fork(getClaimDashboardData),
    fork(triggerClaimEvent),
    fork(triggerClaimSalvageEvent),
    fork(triggerClaimAttributeEvent),
    fork(getClaimFilters),
    fork(saveForm),
    fork(getChoicesData),
    fork(getFeedbackDashboardData),
    fork(getFeedbackListData),
    fork(generateSignedUrl),
    fork(claimsFileUpload),
    fork(getServiceCenterList)
  ])
}
