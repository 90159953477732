import actions from './action'

const initState = {
  getUserListMessage: '',
  userList: []
}

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_USER_LIST_SUCCESS:
    case actions.GET_USER_LIST_FAILURE:
      return {
        ...state,
        getUserListMessage: action.message,
        userList: action.data
      }
    case actions.GET_GROUP_LIST_SUCCESS:
    case actions.GET_GROUP_LIST_FAILURE:
      return {
        ...state,
        getGroupListMessage: action.message,
        groupList: action.data
      }
    case actions.ADD_USER_SUCCESS:
    case actions.ADD_USER_FAILURE:
      return {
        ...state,
        addUserMessage: action.message
      }
    case actions.EDIT_USER_SUCCESS:
    case actions.EDIT_USER_FAILURE:
      return {
        ...state,
        editUserMessage: action.message
      }
    case actions.UPDATE_USER_STATUS_SUCCESS:
    case actions.UPDATE_USER_STATUS_FAILURE:
      return {
        ...state,
        updateUserStatusMessage: action.message
      }
    default:
      return state
  }
}
