import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

export class PermissionChecker extends Component {
  render() {
    const {
      userPermissions,
      requiredPermission
    } = this.props
    if (!!userPermissions.find(({ codename }) => codename === requiredPermission)) {
      return this.props.children
    }
    return null
  }
}

PermissionChecker.propTypes = {
  userPermissions: PropTypes.array.isRequired,
  requiredPermission: PropTypes.string.isRequired
}

PermissionChecker.defaultProps = {
  userPermissions: []
}

export default connect(
  state => ({
    userPermissions: state.App.userPermissions
  }),
  {}
)(PermissionChecker);
