import { Map } from 'immutable'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export function clearToken() {
  localStorage.removeItem('id_token')
  localStorage.removeItem('username')
  localStorage.removeItem('config')
  localStorage.removeItem('permissions')
  const allCookies = cookies.getAll()
  for(let key in allCookies) {
    cookies.remove(key)
  }
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('id_token')
    return new Map({ idToken })
  } catch (err) {
    clearToken()
    return new Map()
  }
}

export function getHeaderUploadFile () {
  let token = getToken().get('idToken')
  return {
    'Content-Type': 'multipart/form-data',
    'Authorization': 'Token ' + token
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime)
  const milliseconds = new Date().getTime() - givenTime.getTime()
  const numberEnding = number => {
    return number > 1 ? 's' : ''
  }
  const number = num => num > 9 ? '' + num : '0' + num
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000)
    const years = Math.floor(temp / 31536000)
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1)
      const day = number(givenTime.getUTCDate())
      const year = givenTime.getUTCFullYear() % 100
      return `${day}-${month}-${year}`
    }
    const days = Math.floor((temp %= 31536000) / 86400)
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days)
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ]
        const month = months[givenTime.getUTCMonth()]
        const day = number(givenTime.getUTCDate())
        return `${day} ${month}`
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600)
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`
    }
    const minutes = Math.floor((temp %= 3600) / 60)
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`
    }
    return 'a few seconds ago'
  }
  return getTime()
}

export function getHeader () {
  let token = getToken().get('idToken')
  return {'Content-Type': 'application/json', 'Authorization': 'Token ' + token}
}


export function dataFormatter (policy) {
  if (policy.length > 0) {
      return policy.map(value => {
          return {
              InternalID: value.InternalID,
              InsurancePolicyNo: value.InsurancePolicyNo,
              PolicyStartDate: value.PolicyStart !== undefined ? value.PolicyStart.Time : '-',
              ProductKey: value.ProductKey,
              MobileNo: value.MobileNo,
              Email: value.Email,
              CreatedAt: value.CreatedAt,
              PolicyState: value.PolicyState,
              UniqueKey: value.UniqueKey
          }
      })
  } else {
      return []
  }
}

export function objectQuery (obj) {
  let query = ''
  for (let key in obj) {
    query += `${query ? '&' : '?'}${key}=${obj[key]}`
  }
  return query
}

export const regExp = {
  email: /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

export const validUrl = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
  return !!pattern.test(str)
}

export const capitalizeFirstLetter = string => {
  const str = string.charAt(0).toUpperCase() + string.slice(1)
  return str.replace(/(_)/gi, ' ')
}

export const getUrlExtension = (string) => {
  return string.toString().split(/\#|\?/)[0]. // eslint-disable-line
    split('.').pop().trim()
}
