import styled from 'styled-components'

export const AddPhoneNumberStyle = styled.div`
  .send-otp-button, .send-otp-button:hover, .send-otp-button:focus {
    background-color: #6ebed2;
    border-color: #6ebed2;
    width: 100%;
  }
  .ant-input-number {
    width: 85%;
  }
`

export const VerifyOTPStyle = styled.div`
  .heading {
    margin-bottom: 20px;
  }
  .ant-input-number {
    width: 100%;
  }
  .verify-otp-button, .verify-otp-button:hover, .verify-otp-button:focus {
    background-color: #6ebed2;
    border-color: #6ebed2;
    width: ${value => value.twoFAMethodTOTP ? '100%' : '48%'};
  }
  .resend-otp-button, .resend-otp-button:hover, .resend-otp-button:focus {
    background-color: #dc826e;
    border-color: #dc826e;
    width: 48%;
    color: #fff;
  }
`

export const AddTOTPDeviceStyle = styled.div`
  .verify-otp-button, .verify-otp-button:hover, .verify-otp-button:focus {
    background-color: #6ebed2;
    border-color: #6ebed2;
    width: 100%;
  }
  .ant-input-number {
    width: 100%;
  }
  .qr-code-container {
    margin-bottom: 16px;
  }
  .qr-code {
    height: 150px !important;
    width: 150px !important;
  }
  .instruction-heading {
    font-weight: 600;
  }
  .instruction-points {
    font-size: 13px;
    color: #a9a9a9;
    margin-left: 18px;
    margin-top: 8px;
    margin-bottom: 14px;
  }
`
