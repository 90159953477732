const GOOGLE_STORAGE_BASE_URL = 'https://storage.googleapis.com/pp_img/pp_insurances/'

export const GENERIC_IMAGES = {
  PASARPOLIS_LOGO: `${GOOGLE_STORAGE_BASE_URL}pp-logo-yellow-cap.png`,
  SIGNIN_BG: `${GOOGLE_STORAGE_BASE_URL}sign.jpg`,
  POLICY_COUNT_ICON: `${GOOGLE_STORAGE_BASE_URL}policy-count-white.svg`,
  GWP_ICON: `${GOOGLE_STORAGE_BASE_URL}money-bag-white.svg`,
  CLAIM_AMOUNT_ICON: `${GOOGLE_STORAGE_BASE_URL}claim-amount-white.svg`,
  TOTAL_CLAIMS_ICON: `${GOOGLE_STORAGE_BASE_URL}totalClaims.png`,
  REJECTED_CLAIMS_ICON: `${GOOGLE_STORAGE_BASE_URL}rejectedClaims.png`,
  SETTLED_CLAIMS_ICON: `${GOOGLE_STORAGE_BASE_URL}settledClaims.png`,
  TOTAL_FEEDBACK_ICON: `${GOOGLE_STORAGE_BASE_URL}totalFeedback.png`,
  PPBROKER_LOGO: `https://storage.googleapis.com/pp_img/ppib_assets/common/ppib-pasarpolis.png`
}
