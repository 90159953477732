import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Layout, Spin, notification } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import authAction from '../../redux/auth/actions'
import appActions from '../../redux/app/actions'
import loadingActions from '../../redux/loading/actions'
import errorActions from '../../redux/error/actions'
import Topbar from '../../components/Topbar'
import AppRouter from './AppRouter'
import { AppStyle } from './App.style'
import { GENERIC_IMAGES } from '../../constants/images'

const { Content, Footer } = Layout

const { logout } = authAction
const { getConfig } = appActions
const { resetLoadingRedux } = loadingActions
const { resetErrorRedux } = errorActions

const logo = (()=>{
  if(window.location.hostname.includes('ppibroker')) return GENERIC_IMAGES.PPBROKER_LOGO
  return GENERIC_IMAGES.PASARPOLIS_LOGO
})()

class App extends Component {
  state = {
    config: [],
    permissions: [],
    configLoading: false
  }

  componentDidMount() {
    this.props.getConfig()
    this.props.resetLoadingRedux(['GET_CONFIG'])
    this.props.resetErrorRedux(['GET_CONFIG'])
  }

  componentWillReceiveProps(nextProps) {
    const {
      userDetails,
      configData = {},
      configLoading,
      configError
    } = nextProps

    if (configLoading === false) {
      if (configError === false) {
        this.setState({
          userDetails
        })
      } else {
        let resetRequestID = get(configData, 'reset_request_id')
        let userEmail = get(configData, 'user_email')
        let configErrorMessage = get(configData, 'message')
        if (resetRequestID) {
          this.props.logout()
          notification['error']({ message: configErrorMessage })
          this.props.history.push(`/reset-password/${resetRequestID}?email=${userEmail}`)
        }
      }
      this.props.resetLoadingRedux(['GET_CONFIG'])
      this.props.resetErrorRedux(['GET_CONFIG'])
    }
  }

  render() {
    const {
      match,
      configLoading,
      userPermissions,
      providerDetails = {}
    } = this.props
    const { url } = match
    const {
      userDetails = {}
    } = this.state
    const { config = [] } = providerDetails

    return (
      <Layout>
        <Spin
          spinning={!!configLoading}
          indicator={<LoadingOutlined type="loading" spin />}
        >
          {
            !configLoading && (
              <AppStyle>
                <Topbar
                  url={url}
                  config={config}
                  permissions={userPermissions}
                  {...this.props}
                />
                <Layout className='layout'>
                  <Layout>
                    <Content>
                      <AppRouter
                        permissions={userPermissions}
                        config={config}
                        url={url}
                        group={userDetails.groups}
                        {...this.props}
                      />
                    </Content>
                    <div>
                      <Footer
                        className='footer'
                      >
                        <p>Powered by</p>
                        <img
                          width='100px'
                          height='22px'
                          className='footer-image'
                          src={logo}
                          alt="Pasarpolis Logo"
                        />
                      </Footer>
                    </div>
                  </Layout>
                </Layout>
              </AppStyle>
            )
          }
        </Spin>
      </Layout>
    )
  }
}

App.propTypes = {
  userDetails: PropTypes.object.isRequired,
  userPermissions: PropTypes.array.isRequired,
  providerDetails: PropTypes.object.isRequired
}

App.defaultProps = {
  userDetails: {},
  userPermissions: [],
  providerDetails: {}
}

export default connect(
  state => ({
    auth: state.Auth,
    userDetails: state.Auth.userDetails,

    userPermissions: state.App.userPermissions,
    providerDetails: state.App.providerDetails,
    configData: state.App.configData,
    configLoading: state.Loading.GET_CONFIG,
    configError: state.Error.GET_CONFIG
  }),
  {
    logout,
    getConfig,
    resetLoadingRedux,
    resetErrorRedux
  }
)(App)
