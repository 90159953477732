import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form, Row, Select, Input, notification } from 'antd'
import { get } from 'lodash'

import { AddPhoneNumberStyle } from './component.style'
import UserActions from '../../../../containers/redux/user/action'
import { PhoneNumberPrefix } from "../../../../helpers/constant"
const { sendOTPToUser } = UserActions

const FormItem = Form.Item
const { Option } = Select

class AddPhoneNumber extends Component {
  state = {
    visible: false
  }

  componentDidMount() {
    this.setState({ visible: this.props.visible })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { visible } = nextProps

    if (visible !== this.state.visible) {
      this.setState({ visible })
      this.props.form.resetFields()
    }
  }

  handleSubmit = values => {
    const { userData } = this.props
    const { phone_number: phoneNumber, prefix } = values
    const phoneNumberWithPrefix = `${prefix}-${phoneNumber}`

    if (get(userData, 'phone') === phoneNumberWithPrefix) {
      return notification.error({ message: 'This number already exists with user. Please enter a different number to proceed.' })
    }
    this.props.sendOTPToUser({
      body: {
        otp_type: 'sms',
        phone_number: phoneNumberWithPrefix
      }
    })
  }

  render() {
    const { sendSMSOTPLoading } = this.props
    const prefixSelector = (
      <FormItem name="prefix" noStyle>
        <Select>
          {PhoneNumberPrefix.map((prefix, index) => (
            <Option key={index} value={prefix.value}>{prefix.label}</Option>
          ))}
        </Select>
      </FormItem>
    )

    return (
      <AddPhoneNumberStyle>
        <Form onFinish={this.handleSubmit} layout='vertical' initialValues={{
          prefix: '62'
        }}>
          <FormItem label='Phone Number' name='phone_number'
            rules={[
              {
                required: true,
                message: 'Phone number is not valid',
                min: 7,
                max: 17
              }
            ]}>
            <Input addonBefore={prefixSelector} />
          </FormItem>
          <Row type='flex'>
            <Button
              type='primary'
              className='send-otp-button'
              htmlType='submit'
              loading={!!sendSMSOTPLoading}
            >
              Send OTP
            </Button>
          </Row>
        </Form>
      </AddPhoneNumberStyle>
    )
  }
}

export default connect(state => ({
  userData: state.Auth.profile,

  sendSMSOTPLoading: state.Loading.SEND_SMS_OTP
}), {
  sendOTPToUser
})(AddPhoneNumber)
