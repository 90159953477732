import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Avatar, Popover, notification, Divider, Modal } from 'antd'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import authAction from '../../redux/auth/actions'
import UserActions from '../../containers/redux/user/action'
import loadingActions from '../../redux/loading/actions'
import errorActions from '../../redux/error/actions'
import TopbarDropdownWrapper from './topbarDropdown.style'
import { TopbarUserStyle } from './topbarUser.style'
import UserProfile from './topbarComponents/userProfile'
import Security from './topbarComponents/security'

const { logout } = authAction
const { resetLoadingRedux } = loadingActions
const { resetErrorRedux } = errorActions
const { updateTopbarActiveModal } = UserActions

class TopbarUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      showProfile: false,
      showSecurity: false
    }
  }

  componentDidMount() {
    this.props.resetLoadingRedux(['LOGOUT'])
    this.props.resetErrorRedux(['LOGOUT'])
  }

  componentWillReceiveProps(nextProps) {
    const { logoutLoading, logoutError, logoutMessage } = nextProps

    if (logoutLoading === false && logoutError) {
      notification['error']({ message: logoutMessage })
      this.props.resetLoadingRedux(['LOGOUT'])
      this.props.resetErrorRedux(['LOGOUT'])
    }
  }

  handleVisibleChange = () => {
    this.setState({ visible: !this.state.visible })
  }

  handleProfileClick = () => {
    this.setState({ showProfile: true, visible: false })
    this.props.updateTopbarActiveModal('profile')
  }

  handleSecurityClick = () => {
    this.setState({ showSecurity: true, visible: false })
    this.props.updateTopbarActiveModal('security')
  }

  renderPopOver = () => {
    const { userDetails } = this.props
    const userName = get(userDetails, 'name', 'User')

    return (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <div
          className="username-dropdown"
        >
          Hello, <span className='user-name'>{userName}</span>
        </div>
        <div>
          <Divider />
          <div className='link' onClick={this.handleProfileClick}>
            Profile
          </div>
          <div className='link' onClick={this.handleSecurityClick}>
            Security
          </div>
        </div>
        <Divider />
        <div
          className="user-dropdown"
          onClick={() => {
            this.props.logout()
          }}
        >
          Logout
        </div>
      </TopbarDropdownWrapper>
    )
  }

  render() {
    const {
      visible
    } = this.state
    const { userDetails } = this.props
    const { showProfile, showSecurity } = this.state
    const userName = get(userDetails, 'name', 'User')

    return (
      <TopbarUserStyle>
        <Modal
          visible={showProfile}
          title='User Profile'
          footer={null}
          onCancel={() => this.setState({ showProfile: false })}
        >
          <UserProfile
            handleClose={() => this.setState({ showProfile: false })}
          />
        </Modal>
        <Modal
          visible={showSecurity}
          title='Security'
          footer={null}
          onCancel={() => this.setState({ showSecurity: false })}
        >
          <Security
            handleClose={() => this.setState({ showSecurity: false })}
          />
        </Modal>
        <Popover
          content={this.renderPopOver()}
          trigger="click"
          visible={visible}
          onVisibleChange={this.handleVisibleChange}
          arrowPointAtCenter={true}
          placement="bottomRight"
        >
          <div>
            <Avatar icon={userName[0]} size='large' className='avatar' />
          </div>
        </Popover>
      </TopbarUserStyle>
    )
  }
}

TopbarUser.propTypes = {
  userDetails: PropTypes.object.isRequired
}

TopbarUser.defaultProps = {
  userDetails: {}
}

export default connect(state => ({
  userDetails: state.App.userDetails,

  logoutLoading: state.Loading.LOGOUT,
  logoutError: state.Error.LOGOUT,
  logoutMessage: state.Auth.logoutMessage
}), {
  logout,
  updateTopbarActiveModal,
  resetLoadingRedux,
  resetErrorRedux
})(TopbarUser)
