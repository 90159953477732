import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import { get } from 'lodash'

import actions from './actions'
import { getToken, clearToken } from '../../helpers/utility';
import noAuthPost from '../../request/methods/post'
import authPost from '../../request/methods/authPost'
import AuthUrls from '../../request/endpoint/auth'

export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(noAuthPost, { ...payload, url: AuthUrls.loginUser })
      if (res.status === 200) {
        const data = res.data
        yield localStorage.setItem('id_token', data.token)
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: data.token,
          data
        })
      } else {
        yield put({
          type: actions.LOGIN_FAILURE,
          message: get(res.data, 'message', 'Error'),
          data: res.data
        })
      }
    } catch (e) {
      yield put({
        type: actions.LOGIN_FAILURE,
        message: get(e.data, 'message', 'Error'),
        data: e.data
      })
    }
  })
}

export function* logout() {
  yield takeEvery(actions.LOGOUT_REQUEST, function* () {
    try {
      let res = yield call(authPost, { url: AuthUrls.logoutUser })
      if (res.status === 200) {
        clearToken()
        yield put({
          type: actions.LOGOUT_SUCCESS,
          message: get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.LOGOUT_FAILURE,
          message: get(res.data, 'error', 'Logout Failed')
        })
      }
    } catch (e) {
      yield put({
        type: actions.LOGOUT_FAILURE,
        message: get(e.data, 'detail', 'Logout Failed')
      })
    }
  })
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken')
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token
      })
    }
  })
}

export function * verifyOTP () {
  yield takeEvery(actions.VERIFY_OTP_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(noAuthPost, { ...payload, url: AuthUrls.verifyOtp })
      if (res.status === 200) {
        const { token } = res.data
        yield localStorage.setItem('id_token', token)
        yield put({
          type: actions.VERIFY_OTP_SUCCESS
        })
        yield put({
          type: actions.CHECK_AUTHORIZATION,
          token
        })
      } else {
        yield put({
          type: actions.VERIFY_OTP_FAILURE
        })
      }
    } catch (e) {
      yield put({
        type: actions.VERIFY_OTP_FAILURE,
        data: e.data
      })
    }
  })
}

export function * resendOTP () {
  yield takeEvery(actions.RESEND_OTP_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(noAuthPost, { ...payload, url: AuthUrls.resendOtp })
      if (res.status === 200) {
        yield put({
          type: actions.RESEND_OTP_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.RESEND_OTP_FAILURE,
          data: res.data
        })
      }
    } catch (e) {
      yield put({
        type: actions.RESEND_OTP_FAILURE,
        data: e.data
      })
    }
  })
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(logout),
    fork(verifyOTP),
    fork(resendOTP)
  ]);
}