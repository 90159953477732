import styled from 'styled-components'
import { APP_COLORS } from '../../constants/colors'

export const TopbarUserStyle = styled.div`
  .avatar {
    background-color: ${APP_COLORS.primary};
    font-size: 18px;
    cursor: pointer;
  }
  .link {
    cursor: pointer;
  }
  .logout {
    color: #FF0000;
  }
  .ant-divider-horizontal {
    margin: 12px 0px !important;
  }
`
