const BASE_URL = process.env.REACT_APP_API_ENDPOINT
const BASE_PATH = '/api/v1/user/'
const BASE_AUTH_PATH = '/api/v1/auth/'

export default {
  forgotPassword: `${BASE_URL}${BASE_PATH}forgot-password/`,
  resetPassword: `${BASE_URL}${BASE_PATH}reset-password/`,
  // Auth Paths
  totpSharedSecret: `${BASE_URL}${BASE_AUTH_PATH}get-totp-shared-secret/`,
  resendUserOTP: `${BASE_URL}${BASE_AUTH_PATH}resend-user-otp/`,
  sendSMSOTP: `${BASE_URL}${BASE_AUTH_PATH}send-otp-to-user/`,
  update2FAMethod: `${BASE_URL}${BASE_AUTH_PATH}update-default-2fa-type/`,
  verifyUserOTP: `${BASE_URL}${BASE_AUTH_PATH}verify-user-otp/`,
  verifyOTPAndAddPhone: `${BASE_URL}${BASE_AUTH_PATH}verify-and-add-number/`,
  verifyTOTP: `${BASE_URL}${BASE_AUTH_PATH}verify-totp/`,
  disable2FA: `${BASE_URL}${BASE_AUTH_PATH}verify-and-disable-2fa/`
}
