import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Row, Modal, notification } from 'antd'
import { get } from 'lodash'

import { UserProfileStyle } from '../topbar.style'
import AddPhoneNumber from './SecurityComponents/addPhoneNumber'
import VerifyOTP from './SecurityComponents/verifyOTP'
import loadingActions from '../../../redux/loading/actions'
import errorActions from '../../../redux/error/actions'

const { resetLoadingRedux } = loadingActions
const { resetErrorRedux } = errorActions

class UserProfile extends Component {
  state = {
    showAddPhoneNumberModal: false,
    showVerifyOTPModal: false
  }

  componentDidMount () {
    this.props.resetLoadingRedux(['SEND_SMS_OTP', 'VERIFY_AND_ADD_PHONE'])
    this.props.resetErrorRedux(['SEND_SMS_OTP', 'VERIFY_AND_ADD_PHONE'])
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      sendSMSOTPLoading, sendSMSOTPError, sendSMSOTPMessage,
      verifyAndAddNumberLoading, verifyAndAddNumberError, verifyAndAddNumberMessage,
      topbarActiveModal
    } = nextProps
    const isModalActive = topbarActiveModal === 'profile'

    if (isModalActive && sendSMSOTPLoading === false) {
      notification[sendSMSOTPError ? 'error' : 'success']({ message: sendSMSOTPMessage })
      if (sendSMSOTPError === false) {
        this.setState({ showVerifyOTPModal: true, showAddPhoneNumberModal: false })
      }
      this.props.resetLoadingRedux(['SEND_SMS_OTP'])
      this.props.resetErrorRedux(['SEND_SMS_OTP'])
    }

    if (isModalActive && verifyAndAddNumberLoading === false) {
      notification[verifyAndAddNumberError ? 'error' : 'success']({ message: verifyAndAddNumberMessage })
      if (verifyAndAddNumberError === false) {
        this.setState({ showVerifyOTPModal: false })
      }
      this.props.resetLoadingRedux(['VERIFY_AND_ADD_PHONE'])
      this.props.resetErrorRedux(['VERIFY_AND_ADD_PHONE'])
    }
  }

  handleAddPhoneNumber = () => {
    this.setState({ showAddPhoneNumberModal: true })
    this.props.handleClose()
  }

  render () {
    const { userData = {} } = this.props
    const { showAddPhoneNumberModal, showVerifyOTPModal } = this.state

    return (
      <UserProfileStyle>
        {
          showAddPhoneNumberModal &&
          <Modal
            visible
            title='Add Phone Number'
            footer={null}
            onCancel={() => this.setState({ showAddPhoneNumberModal: false })}
          >
            <AddPhoneNumber
              visible={showAddPhoneNumberModal}
            />
          </Modal>
        }
        {
          showVerifyOTPModal &&
          <Modal
            visible
            title='Verify OTP'
            footer={null}
            onCancel={() => this.setState({ showVerifyOTPModal: false })}
          >
            <VerifyOTP
              visible={showVerifyOTPModal}
            />
          </Modal>
        }

        <Row>
          <Col span={8} className='heading'>Name:</Col>
          <Col>{get(userData, 'name')}</Col>
        </Row>
        <Row>
          <Col span={8} className='heading'>Email:</Col>
          <Col>{get(userData, 'email')}</Col>
        </Row>
        <Row>
          <Col span={8} className='heading'>Phone:</Col>
          <Col>{get(userData, 'phone')}<img src='https://storage.googleapis.com/pp_img/pencil-icon.png' alt='edit-icon' className='change-icon' onClick={this.handleAddPhoneNumber} /></Col>
        </Row>
      </UserProfileStyle>
    )
  }
}

export default connect(state => ({
  userData: state.App.userDetails,

  sendSMSOTPLoading: state.Loading.SEND_SMS_OTP,
  sendSMSOTPError: state.Error.SEND_SMS_OTP,
  sendSMSOTPMessage: state.User.sendSMSOtpMessage,

  verifyAndAddNumberLoading: state.Loading.VERIFY_AND_ADD_PHONE,
  verifyAndAddNumberError: state.Error.VERIFY_AND_ADD_PHONE,
  verifyAndAddNumberMessage: state.User.verifyAndAddNumberMessage,

  topbarActiveModal: state.User.topbarActiveModal
}), {
  resetLoadingRedux,
  resetErrorRedux
})(UserProfile)
