import actions from './actions'

const initState = {
  userPermissions: [],
  userDetails: {},
  providerDetails: {}
}

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_CONFIG_SUCCESS:
    case actions.GET_CONFIG_FAILURE:
      return {
        ...state,
        userPermissions: action.userPermissions,
        providerDetails: action.providerDetails,
        userDetails: action.userDetails,
        slab: action.slab,
        configData: action.data
      }
    default:
      return state
  }
}
