const actions = {
  GET_DASHBOARD_DATA_REQUEST: 'GET_DASHBOARD_DATA_REQUEST',
  GET_DASHBOARD_DATA_SUCCESS: 'GET_DASHBOARD_DATA_SUCCESS',
  GET_DASHBOARD_DATA_FAILURE: 'GET_DASHBOARD_DATA_FAILURE',

  GET_POLICY_LIST_REQUEST: 'GET_POLICY_LIST_REQUEST',
  GET_POLICY_LIST_SUCCESS: 'GET_POLICY_LIST_SUCCESS',
  GET_POLICY_LIST_FAILURE: 'GET_POLICY_LIST_FAILURE',

  GET_POLICY_LIST_COUNT_REQUEST: 'GET_POLICY_LIST_COUNT_REQUEST',
  GET_POLICY_LIST_COUNT_SUCCESS: 'GET_POLICY_LIST_COUNT_SUCCESS',
  GET_POLICY_LIST_COUNT_FAILURE: 'GET_POLICY_LIST_COUNT_FAILURE',

  EMAIL_LIST_REQUEST: 'EMAIL_LIST_REQUEST',
  EMAIL_LIST_SUCCESS: 'EMAIL_LIST_SUCCESS',
  EMAIL_LIST_FAILURE: 'EMAIL_LIST_FAILURE',

  EMAIL_LIST_REQUEST_RANGE: 'EMAIL_LIST_REQUEST_RANGE',
  EMAIL_LIST_SUCCESS_RANGE: 'EMAIL_LIST_SUCCESS_RANGE',
  EMAIL_LIST_FAILURE_RANGE: 'EMAIL_LIST_FAILURE_RANGE',

  GET_POLICY_DETAILS_REQUEST: 'GET_POLICY_DETAILS_REQUEST',
  GET_POLICY_DETAILS_SUCCESS: 'GET_POLICY_DETAILS_SUCCESS',
  GET_POLICY_DETAILS_FAILURE: 'GET_POLICY_DETAILS_FAILURE',

  GET_PARTNERS_LIST_REQUEST: 'GET_PARTNERS_LIST_REQUEST',
  GET_PARTNERS_LIST_SUCCESS: 'GET_PARTNERS_LIST_SUCCESS',
  GET_PARTNERS_LIST_FAILURE: 'GET_PARTNERS_LIST_FAILURE',

  GET_PRODUCTS_LIST_REQUEST: 'GET_PRODUCTS_LIST_REQUEST',
  GET_PRODUCTS_LIST_SUCCESS: 'GET_PRODUCTS_LIST_SUCCESS',
  GET_PRODUCTS_LIST_FAILURE: 'GET_PRODUCTS_LIST_FAILURE',

  GET_PACKAGES_LIST_REQUEST: 'GET_PACKAGES_LIST_REQUEST',
  GET_PACKAGES_LIST_SUCCESS: 'GET_PACKAGES_LIST_SUCCESS',
  GET_PACKAGES_LIST_FAILURE: 'GET_PACKAGES_LIST_FAILURE',

  GET_CHANGE_HISTORY_DATA_REQUEST: 'GET_CHANGE_HISTORY_DATA_REQUEST',
  GET_CHANGE_HISTORY_DATA_SUCCESS: 'GET_CHANGE_HISTORY_DATA_SUCCESS',
  GET_CHANGE_HISTORY_DATA_FAILURE: 'GET_CHANGE_HISTORY_DATA_FAILURE',

  GET_PAYMENT_LIST_REQUEST: 'GET_PAYMENT_LIST_REQUEST',
  GET_PAYMENT_LIST_SUCCESS: 'GET_PAYMENT_LIST_SUCCESS',
  GET_PAYMENT_LIST_FAILURE: 'GET_PAYMENT_LIST_FAILURE',

  getDashboardData: payload => ({
    type: actions.GET_DASHBOARD_DATA_REQUEST,
    payload
  }),
  getPolicyList: payload => ({
    type: actions.GET_POLICY_LIST_REQUEST,
    payload
  }),
  getPolicyListCount: payload => ({
    type: actions.GET_POLICY_LIST_COUNT_REQUEST,
    payload
  }),
  emailList: payload => ({
    type: actions.EMAIL_LIST_REQUEST,
    payload
  }),
  emailRangeList: payload => ({
    type: actions.EMAIL_LIST_REQUEST_RANGE,
    payload
  }),
  getPolicyDetails: payload => ({
    type: actions.GET_POLICY_DETAILS_REQUEST,
    payload
  }),
  getPartnersList: payload => ({
    type: actions.GET_PARTNERS_LIST_REQUEST,
    payload
  }),
  getProductsList: payload => ({
    type: actions.GET_PRODUCTS_LIST_REQUEST,
    payload
  }),
  getPackagesList: payload => ({
    type: actions.GET_PACKAGES_LIST_REQUEST,  
    payload
  }),
  getChangeHistoryData: payload => ({
    type: actions.GET_CHANGE_HISTORY_DATA_REQUEST,
    payload
  }),
  getPaymentList: payload => ({
    type: actions.GET_PAYMENT_LIST_REQUEST,  
    payload
  }),
}
export default actions
