import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import _ from 'lodash'
import actions from './action'
import claimsAction from '../claims/actions'
import authGet from '../../../request/methods/authGet'
import authPost from '../../../request/methods/authPost'
import policyUrls from '../../../request/endpoint/policy'

export function* getDashboardData() {
  yield takeEvery(actions.GET_DASHBOARD_DATA_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: policyUrls.getDashboardData })
      if (res.status === 200) {
        yield put({
          type: actions.GET_DASHBOARD_DATA_SUCCESS,
          data: res.data,
          message: _.get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_DASHBOARD_DATA_FAILURE,
          data: [],
          message: _.get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_DASHBOARD_DATA_FAILURE,
        data: [],
        message: _.get(e.data, 'message', 'Error')
      })
    }
  })
}

export function* getPolicyList() {
  yield takeEvery(actions.GET_POLICY_LIST_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: policyUrls.getPolicyList })
      if (res.status === 200) {
        yield put({
          type: actions.GET_POLICY_LIST_SUCCESS,
          data: res.data,
          message: _.get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_POLICY_LIST_FAILURE,
          data: {},
          message: _.get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_POLICY_LIST_FAILURE,
        data: {},
        message: _.get(e.data, 'message', 'Error')
      })
    }
  })
}

export function* getPolicyListCount() {
  yield takeEvery(actions.GET_POLICY_LIST_COUNT_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: policyUrls.getPolicyListCount })
      if (res.status === 200) {
        yield put({
          type: actions.GET_POLICY_LIST_COUNT_SUCCESS,
          data: res.data.metadata,
          message: _.get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_POLICY_LIST_COUNT_FAILURE,
          data: {},
          message: _.get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_POLICY_LIST_COUNT_FAILURE,
        data: {},
        message: _.get(e.data, 'message', 'Error')
      })
    }
  })
}

export function* emailList() {
  yield takeEvery(actions.EMAIL_LIST_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: policyUrls.emailList })
      if (res.status === 200) {
        yield put({
          type: actions.EMAIL_LIST_SUCCESS,
          data: res.data.metadata,
          message: _.get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.EMAIL_LIST_FAILURE,
          data: {},
          message: _.get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.EMAIL_LIST_FAILURE,
        data: {},
        message: _.get(e.data, 'error', 'Error')
      })
    }
  })
}

export function* emailRangeList() {
  yield takeEvery(actions.EMAIL_LIST_REQUEST_RANGE, function* ({ payload }) {
    try {
      let res = yield call(authPost, { ...payload, url: policyUrls.emailRangeList })
      if (res.status === 200) {
        yield put({
          type: actions.EMAIL_LIST_SUCCESS_RANGE,
          data: res.data.metadata,
          message: _.get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.EMAIL_LIST_FAILURE_RANGE,
          data: {},
          message: _.get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.EMAIL_LIST_FAILURE_RANGE,
        data: {},
        message: _.get(e.data, 'error', 'Error')
      })
    }
  })
}

export function* getPolicyDetails() {
  yield takeEvery(actions.GET_POLICY_DETAILS_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: policyUrls.getPolicyDetails })
      if (res.status === 200) {
        const { ApplicationNo } = res.data
        const payload = { query: { application_no: ApplicationNo } }
        yield put({
          type: actions.GET_POLICY_DETAILS_SUCCESS,
          data: res.data,
          message: _.get(res.data, 'message', 'Success')
        })
        yield put({
          type: claimsAction.GET_CLAIM_LIST_REQUEST,
          payload
        })
      } else {
        yield put({
          type: actions.GET_POLICY_DETAILS_FAILURE,
          data: {},
          message: _.get(res.data, 'error', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_POLICY_DETAILS_FAILURE,
        data: {},
        message: _.get(e.data, 'error', 'Error')
      })
    }
  })
}

export function* getPartnersList() {
  yield takeEvery(actions.GET_PARTNERS_LIST_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: policyUrls.getPartnersList })
      if (res.status === 200) {
        yield put({
          type: actions.GET_PARTNERS_LIST_SUCCESS,
          data: res.data.data,
          message: _.get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_PARTNERS_LIST_FAILURE,
          data: [],
          message: _.get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_PARTNERS_LIST_FAILURE,
        data: [],
        message: _.get(e.data, 'message', 'Error')
      })
    }
  })
}

export function* getProductsList() {
  yield takeEvery(actions.GET_PRODUCTS_LIST_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: policyUrls.getProductsList })
      if (res.status === 200) {
        yield put({
          type: actions.GET_PRODUCTS_LIST_SUCCESS,
          data: res.data.data,
          message: _.get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_PRODUCTS_LIST_FAILURE,
          data: [],
          message: _.get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_PRODUCTS_LIST_FAILURE,
        data: [],
        message: _.get(e.data, 'message', 'Error')
      })
    }
  })
}

export function* getPackagesList() {
  yield takeEvery(actions.GET_PACKAGES_LIST_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: policyUrls.getPackagesList })
      if (res.status === 200) {
        yield put({
          type: actions.GET_PACKAGES_LIST_SUCCESS,
          data: res.data.data,
          message: _.get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_PACKAGES_LIST_FAILURE,
          data: [],
          message: _.get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_PACKAGES_LIST_FAILURE,
        data: [],
        message: _.get(e.data, 'message', 'Error')
      })
    }
  })
}

export function* getChangeHistoryRequest() {
  yield takeEvery(actions.GET_CHANGE_HISTORY_DATA_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(authGet, { ...payload, url: policyUrls.getChangehistoryUrl })
      if (res.status === 200) {
        yield put({ type: actions.GET_CHANGE_HISTORY_DATA_SUCCESS, data: res.data })
      } else {
        yield put({ type: actions.GET_CHANGE_HISTORY_DATA_FAILURE, data: [] })
      }
    } catch (e) {
      yield put({ type: actions.GET_CHANGE_HISTORY_DATA_FAILURE, data: []})
    }
  })
}

export function* getPayementList() {
  yield takeEvery(actions.GET_PAYMENT_LIST_REQUEST, function* ({ payload }) {
    try {
      let res = yield call(authGet, { ...payload, url: policyUrls.getPaymentListUrl })
      if (res.status === 200) {
        yield put({
          type: actions.GET_PAYMENT_LIST_SUCCESS,
          data: res.data,
          message: _.get(res.data, 'message', 'Success')
        })
      } else {
        yield put({
          type: actions.GET_PAYMENT_LIST_FAILURE,
          data: {},
          message: _.get(res.data, 'message', 'Error')
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_PAYMENT_LIST_FAILURE,
        data: [],
        message: _.get(e.data, 'message', 'Error')
      })
    }
  })
}


export default function* rootSaga() {
  yield all([
    fork(getDashboardData),
    fork(getPolicyList),
    fork(getPolicyListCount),
    fork(emailList),
    fork(getPolicyDetails),
    fork(getPartnersList),
    fork(getProductsList),
    fork(getPackagesList),
    fork(getChangeHistoryRequest),
    fork(getPayementList),
    fork(emailRangeList)
  ])
}
